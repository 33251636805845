import { ACTIVITY_DETAILS } from "../../constant/ActionType";

export default function ActivityDetailRedc(
    state = {
        activityDetails: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ACTIVITY_DETAILS:
            return {
                ...state,
                activityDetails: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
