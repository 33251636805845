import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination, Button } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { allLeadsColumns } from "components/columns/columns";
import { FetchAllLeads, FetchAllDeatilsLeads } from "store/action/index";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DrawerLeadDetail from "./lead-detail";
import {
  can_view_leads,
  can_view_leads_details,
  can_view_leads_history,
  an_view_campaign_leads,
} from "helper/permissionsCheck";

const AllLeads = ({ allLeadsData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingD, setLoadingD] = useState(false);
  const [params, setParams] = useState({ page: 1 });

  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllLeads({ setLoading: setLoading, params: params }));
  }, [params]);

  const columns = allLeadsColumns({ params, setParams });

  const HandleViewDetailsFunction = (record) => {
    setLoadingD(true);
    dispatch(
      FetchAllDeatilsLeads({ id: record?.id, setLoadingD: setLoadingD })
    );
  };

  const HandleEdit = (record) => {
    navigate("/leads/add-lead", { state: record });
  };

  if (can_view_leads_details) {
    columns.push({
      title: "Action",
      key: "actions",
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            <DrawerLeadDetail loadingD={loadingD}>
              <Button
                type="primary"
                onClick={() => HandleViewDetailsFunction(record)}
              >
                View Detail
              </Button>
            </DrawerLeadDetail>
            <Button
              onClick={() => HandleEdit(record)}>Edit</Button>
          </div>
        );
      },
    });
  }

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Leads
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Link to={"/leads/add-lead"}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Lead
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      {can_view_leads ? <MainCard>
        <div style={{ overflowX: "auto" }}>
          <Table
            dataSource={allLeadsData?.data ?? []}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allLeadsData?.meta?.current_page || 1}
            total={allLeadsData?.meta?.total || 0}
            pageSize={allLeadsData?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allLeadsData: state?.AllLeadsReducer?.allLeads,
  };
};

export default connect(mapStateToProps)(AllLeads);
