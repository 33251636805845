import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FetchAllWhatsappTemplates, FetchPostingChannel, FetchWhatsappChannel, FetchWhatsappTemplateSubIDAgains } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin, Tag, Select, Tooltip } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { IoIosLink } from "react-icons/io";
import { MdOutlineRefresh } from "react-icons/md";
import '../../assets/css/custom.css'
import {
  can_add_whatsapp_templates,
  can_view_whatsapp_templates,

} from "helper/permissionsCheck";

const WhatsappTemplate = ({ whatsappTemplates, updatedwhatsappTemplate }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSync, setLoadingSync] = useState(false);
  const selector = useSelector((state) => state);
  const navigate = useNavigate();
  const { Option } = Select;
  useEffect(() => {
    dispatch(FetchAllWhatsappTemplates({ setLoading: setLoading }));
  }, []);
  useEffect(() => {
    dispatch(FetchWhatsappChannel())
  }, []);
  const renderButtons = (text) => {
    try {
      const parsedBody = JSON.parse(text);
      if (!parsedBody?.content?.keyboard?.rows) return null;

      return (
        <div className="flex flex-col gap-2">
          {parsedBody.content.keyboard.rows.map((row, rowIndex) => (
            <div key={rowIndex} className="d-flex gap-2">
              {row.buttons.map((button, buttonIndex) => {

                let buttonContent;
                if (button.buttonType === "URL") {
                  buttonContent = (
                    <Tooltip title={button.text + " " + button.url} >  <IoIosLink style={{ fontSize: "22px", cursor: 'pointer' }} />
                    </Tooltip>
                  );
                } else if (button.buttonType === "PHONE") {
                  buttonContent = (
                    <Tooltip title={button.text + " " + button.phone} > <IoIosCall style={{ fontSize: "22px", cursor: 'pointer' }} /></Tooltip>
                  );
                }
                // console.log(buttonContent, 'button Content')
                return (
                  <div key={buttonIndex}>
                    {buttonContent}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      );
    } catch (error) {
      console.error("Error parsing buttons:", error);
      return null;
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "content",
      render: (text) => {
        try {
          const parsedBody = JSON.parse(text);
          // console.log(parsedBody, 'parsed body')
          return parsedBody?.content?.text || text;
        } catch (error) {
          return text;
        }
      }
    },
    {
      title: "Footer",
      dataIndex: "body",
      key: "footer",
      render: (text) => {
        try {
          const parsedBody = JSON.parse(text);
          return parsedBody?.content?.footer?.text;
        } catch (error) {
          return text;
        }
      }
    },
    {
      title: "Status",
      dataIndex: "body",
      key: "status",
      render: (text) => {
        try {
          const parsedBody = JSON.parse(text);
          return <Tag color={parsedBody?.status === 'APPROVED' ? 'green' : parsedBody?.status === 'PENDING' ? 'yellow' : 'red'}>{parsedBody?.status}</Tag>;
        } catch (error) {
          return text;
        }
      }
    },
    {
      title: "Buttons",
      dataIndex: "body",
      key: "buttons",
      render: renderButtons
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => {
    //     const editButton = true ? (
    //       <IconButton
    //         className="mx-2"
    //         type="primary"
    //         variant="contained"
    //         onClick={() => navigate(`/templates/edit-whatsapp-template/${record.id}`)}
    //       >
    //         <EditOutlined />
    //       </IconButton>
    //     ) : null;

    //     return <span>{editButton}</span>;
    //   },
    // }
  ];

  const HandleNavigate = () => {
    navigate("/templates/create-whatsapp-templates");
  };

  const HandleSyncFetch = () => {
    setLoadingSync(true)
    dispatch(FetchPostingChannel({ setLoading: setLoadingSync }));
  }
  const handleChannelSelect = (channelId) => {
    dispatch(FetchWhatsappTemplateSubIDAgains({ setLoading: setLoading, subjecdId: channelId }));
  };
  const AllchannelData = selector?.AllWhatsappChannnel?.allwhatsappchannel ?? [];
  // const activeChannels = AllchannelData?.filter(channel => channel.active == 1);
  const activeChannels = Array.isArray(AllchannelData)
    ? AllchannelData.filter(channel => channel.active === 1)
    : [];

  return (
    <>
      {can_add_whatsapp_templates ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Default for mobile
            justifyContent: "space-between",
            width: "100%",
            height: "auto", // Adjust height for mobile
            borderRadius: "10px",
            marginBottom: "20px",
            padding: "16px",
            backgroundColor: "#fff",
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            {/* Title Section */}
            <Grid item xs={12} sm={6}>
              <span
                style={{
                  fontSize: "18px", // Smaller font for mobile
                  fontWeight: "600",
                }}
              >
                All Whatsapp Templates
              </span>
            </Grid>
            {/* Action Section */}
            <Grid
              item
              xs={12}
              sm={6}
              container
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-end" }}
              spacing={1}
            >
              {true ? (
                <>
                  <Grid item>
                    <Select
                      placeholder="Select a channel"
                      onChange={handleChannelSelect}
                      className="ant-height"
                      style={{ minWidth: "150px", width: "100%" }} // Adjust width for mobile
                    >
                      {Array.isArray(activeChannels) && activeChannels.length !== 0 &&
                        activeChannels.map((channel) => (
                          <Option key={channel.id} value={channel.subjectId}>
                            {channel.name}
                          </Option>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Button
                      disableElevation
                      className="px-4"
                      size="medium"
                      onClick={HandleSyncFetch}
                      type="submit"
                      endIcon={<MdOutlineRefresh />}
                      variant="outlined"
                      style={{ width: "100%" }} // Adjust width for mobile
                    >
                      {loadingSync ? 'Syncing...' : 'Sync'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disableElevation
                      className="px-2"
                      size="medium"
                      onClick={HandleNavigate}
                      type="submit"
                      style={{ width: "100%" }} // Adjust width for mobile
                    >
                      <span className="mx-2">
                        <IoMdAddCircle color="#FF264C" size={26} />
                      </span>{" "}
                      Add Template
                    </Button>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </div>
      ) : null}

      {can_view_whatsapp_templates ?
        <MainCard>
          <div style={{ overflowX: "auto" }}>
            <Spin spinning={loading}>
              <Table
                className="border rounded"
                style={{ marginTop: 25 }}
                dataSource={whatsappTemplates ?? []}
                columns={columns}
              />
            </Spin>
          </div>
        </MainCard> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    whatsappTemplates: state.WhatsappTemplatesReducer.whatsappTemplates,
    updatedwhatsappTemplate: state.UpdateReducerWhatsappTemplateSUbjid.updatedwhatsappTemplate,
  };
};

export default connect(mapStateToProps)(WhatsappTemplate);