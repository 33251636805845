import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Space,
  Form,
  Select,
  DatePicker,
  Row,
  Table,
  Col,
} from "antd";
import CsvUploaderLeads from "components/inputs/CsvUploaderLeads";
import { useSelector } from "react-redux";
import PaginatedSelect from "components/selects/SelectPagination";
import { dispatch } from "store/index";
import { FetchAllSelectLeads, AddLeadActivity } from "store/action/index";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { CloseOutlined } from '@ant-design/icons';
import { EditActivityLeads } from "components/columns/columns";

const AddLeadsDrawer = ({ children, datacampaign, handleNavigate, leadsDataSource, status, type }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [filteredLeadsData, setFilteredLeadsData] = useState([]);
  const [totalLeads, setTotalLeads] = useState([]);

  const selector = useSelector((state) => state);
  const allLeadsData = selector?.AllLeadsSelectReducer?.allLeads?.data ?? [];
  const allMembersData = selector?.GetAllUsers?.getAllUsers?.users ?? [];
  const allActivitiesData = selector?.AllActivities?.allActivities?.data ?? [];

  useEffect(() => {
    dispatch(FetchAllSelectLeads());
  }, [])

  useEffect(() => {
    if (leadsDataSource && allLeadsData.length > 0) {
      const existingLeads = [
        ...(leadsDataSource || []),
      ];
      setTotalLeads(existingLeads);
      const existingLeadIds = new Set(existingLeads.map(lead => lead.id));
      const filtered = allLeadsData.filter(lead =>
        !existingLeadIds.has(lead.id));
      setFilteredLeadsData(filtered);
    } else {
      setFilteredLeadsData(allLeadsData);
    }
  }, [leadsDataSource, allLeadsData]);

  const showLargeDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setDataSource([]);
  };

  const onFinish = (values) => {
    const allValues = {
      ...form.getFieldValue(),
      "csv-leads": dataSource,
      activity_id: datacampaign?.activity_id_specific,
    };

    if (dataSource.length || (allValues?.leads && allValues.leads?.length)) {
      if (allValues?.start_date?.$d) {
        allValues.start_date = new Date(allValues.start_date.$d)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-");
      }
      dispatch(AddLeadActivity({ fieldData: allValues, type }));
      onClose();
    } else {
      toast.error("Please Select a lead or Upload CSV");
    }
  };

  const callAllLeadsApi = (setLoading, params) => {
    dispatch(FetchAllSelectLeads({ setLoading: setLoading, params: params }));
  };

  return (
    <>
      <div onClick={showLargeDrawer}>{children}</div>
      <Drawer
        title="Add Leads"
        width={800}
        placement="right"
        onClose={onClose}
        open={open}
        closable={true}
        destroyOnClose={true}
      >
        <div className="container-fluid mt-1">
          <div>
            <Button
              onClick={onClose}
              type="text"
              style={{ margin: "0px", marginBottom: "-30px" }}
              icon={<CloseOutlined />}
            />
          </div>
          <Form
            form={form}
            name="addLeads"
            onFinish={onFinish}
            autoComplete="off"
            className="mt-3"
          >
            <Typography className="text-center mb-3" variant="h2">
              ADD LEADS
            </Typography>

            <Table
              className="border rounded"
              style={{ margin: '25px 0' }}
              dataSource={totalLeads ?? []}
              columns={EditActivityLeads()}
            />
            {(status == 0 || status == 2) && <>
              <Row gutter={16}>

                <Col span={12}>
                  <Form.Item
                    label="Leads"
                    name="leads"
                  >
                    <PaginatedSelect
                      optKey="name"
                      optValue="id"
                      opt={filteredLeadsData} // Using filtered leads instead of allLeadsData
                      callApi={callAllLeadsApi}
                      allData={{
                        ...selector?.AllLeadsSelectReducer?.allLeads,
                        data: filteredLeadsData
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <CsvUploaderLeads
                setDataSource={setDataSource}
                dataSource={dataSource}
              />

              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button onClick={onClose}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </>}
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default AddLeadsDrawer;