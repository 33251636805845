import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, InputNumber } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import {
  FetchCreateEmailSetting,
  FetchUpdateEmailSetting,
} from "store/action/index";
const { Option } = Select;

const EmailSetting = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loadinBtn, setLoadingBtn] = useState(false)

  const isEditing = location.state ? true : false;
  const initialData = location.state ? location.state : null;

  useEffect(() => {
    if (isEditing && initialData) {
      form.setFieldsValue({
        hostname: initialData.hostname,
        username: initialData.username,
        password: initialData.password,
        port: initialData.port,
        encryption_method: initialData.encryption_method,
        active: initialData.active == 1 ? true : false,
      });
    }
  }, [form, initialData, isEditing]);

  const onFinish = (values) => {
    setLoadingBtn(true)
    if (isEditing) {
      message.success("Email setting updated successfully!");
      const payload = {
        ...(values && { id: initialData?.id, _method: "PUT" }),
        hostname: values.hostname,
        username: values.username,
        password: values.password,
        port: values.port,
        encryption_method: values.encryption_method,
        active: values.active,
        from_address: null,
      };
      dispatch(FetchUpdateEmailSetting({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
      navigate("/settings/all-email-setting");
    } else {
      message.success("Email setting created successfully!");
      dispatch(FetchCreateEmailSetting({ fieldData: values, setLoadingBtn: setLoadingBtn }));
      navigate("/settings/all-email-setting");
    }
  };

  return (
    <MainCard>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="hostname"
          label="Hostname"
          tooltip="Enter the hostname"
          rules={[{ required: true, message: "Please enter the hostname" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="username"
          label="Username"
          tooltip="Enter the username"
          rules={[{ required: true, message: "Please enter the username" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          tooltip="Enter the password"
          rules={[{ required: true, message: "Please enter the password" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="port"
          label="Port"
          tooltip="Enter the port number"
          rules={[{ required: true, message: "Please enter the port number" }]}
        >
          <InputNumber style={{ width: "100%" }} min={1} max={65535} />
        </Form.Item>

        <Form.Item
          name="encryption_method"
          label="Encryption Method"
          tooltip="Select the encryption method"
          rules={[
            { required: true, message: "Please select the encryption method" },
          ]}
        >
          <Select placeholder="Select encryption method">
            <Option value="tsl">tsl</Option>
          </Select>
        </Form.Item>


        <Form.Item
          name="active"
          label="Status"
          tooltip="Select the active status"
          rules={[{ required: true, message: "Please select the status" }]}
        >
          <Select>
            <Option value={true}>Active</Option>
            <Option value={false}>Deactive</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadinBtn}>
            {isEditing ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </MainCard>
  );
};

export default EmailSetting;
