import React from "react";
import MainCard from "components/MainCard";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { Modal, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useState } from "react";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import { dispatch } from "store/index";
import { CreateAllSMSTemplates, EditAllSMSTemplates } from "store/action/index";

const CreateEditSmsTemplate = ({ children, setLoading, data }) => {
    const { TextArea: TextAreaInput } = Input;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [form] = Form.useForm();
    const [editorValue, setEditorValue] = useState(data?.body || ""); 

    const showModal = () => {
        setIsModalOpen(true);
        if (data) {
            form.setFieldsValue({
                name: data?.name,
                body: data?.body,

            });
            setEditorValue(data?.body);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                const payload = {
                    ...(data && { id: data?.id, _method: "PUT" }),
                    name: values.name,
                    body: values.body,
                };
                setLoadingBtn(true)
                if (data) {
                    dispatch(EditAllSMSTemplates({ payload, setLoading, setLoadingBtn: setLoadingBtn }));
                } else {

                    dispatch(CreateAllSMSTemplates({ payload, setLoading, setLoadingBtn: setLoadingBtn }));
                }

                form.resetFields();
                setEditorValue(""); 
                handleCancel();
            })
            .catch(() => {
                toast.error("Please fill out all required fields", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    bodyClassName: "toastStyle",
                });
            });
    };

    return (
        <>
            <span onClick={showModal}>{children}</span>
            <Modal
                title={data ? "Update Sms Template" : "Add Sms Template"}
                open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                centered
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        name: data?.name || "",
                        body: data?.body || "",

                    }}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: "Template Name is required" }]}
                    >
                        <Input placeholder="Enter template name" />
                    </Form.Item>
                    <Form.Item
                        label="Body"
                        name="body"
                        rules={[{ required: true, message: " Body is required" }]}
                    >
                        <TextAreaInput rows={4} placeholder="Enter Body" />
                    </Form.Item>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                // onClick={handleSubmit}
                                disableElevation
                                className="px-4"
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {loadingBtn ? "Processing..." : (data ? "Update" : "Add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(CreateEditSmsTemplate);
