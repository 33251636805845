import { SessionState } from "sip.js";
import { useSessionCall, SessionDirection } from "react-sipjs";

import { CallTimer } from "./CallTimer";
import { useEffect } from "react";
import img1 from '../../assets/images/1.gif';
import img2 from '../../assets/images/3.gif';
import img3 from '../../assets/images/6.gif';


export const CallSessionItem = (props) => {
  const { sessionId, handleStartCall } = props;
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    direction,
    timer,
    callId,
    callid

  } = useSessionCall(sessionId);

  const calculateDuration = (startTime) => {
    if (!startTime) return '00:00';
    try {
      const currentTime = new Date();
      const startDate = new Date(startTime);
      if (isNaN(startDate.getTime())) {
        console.error('Invalid start time:', startTime);
        return '00:00';
      }
      const differenceInSeconds = Math.floor((currentTime - startDate) / 1000);
      const formattedDuration = new Date(differenceInSeconds * 1000)?.toISOString().slice(14, 19);
      return formattedDuration;
    } catch (error) {
      console.error('Error calculating duration:', error);
      return '00:00';
    }
  };

  useEffect(() => {
    if (session.state == 'Established') {
      handleStartCall('Established')
    }
    if (session.state == 'Terminated' && !timer?.answeredAt) {
      handleStartCall('Terminated', null, timer?.answeredAt)
    }
    if (session.state == 'Terminated' && (timer?.answeredAt)) {
      console.log("AnswrAtttRuning")
      handleStartCall('Terminated', calculateDuration(timer?.answeredAt), timer?.answeredAt, `${timer?.answeredAt, session?._id.replace(session?.fromTag, '')}.wav`)
    }
  }, [session.state])

  return (
    <li className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <div className="h-12 w-12 flex-none rounded-full bg-gray-400"></div>
        <div className="min-w-0 flex-auto">
          {/* <p className="text-sm font-semibold leading-6 text-gray-900">
            Session ID: {session.id}
          </p> */}
          <div className="flex gap-2">
            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
              {session.state === 'Establishing' || session.state === 'Initial' ? <img src={img1} width={100} /> : session.state === 'Established' ? <img src={img2} width={100} /> : session.state === 'Terminated' ? <img src={img3} width={100} /> : ''}
            </p>

            {/* {session.state === SessionState.Initial && (
              <>
                <button
                  onClick={answer}
                  className="text-[0.8rem] bg-transparent hover:bg-blue-500 text-blue-700 font-bold hover:text-white py-[0.1rem] px-[0.3rem] border border-blue-500 hover:border-transparent rounded"
                >
                  Answer
                </button>
                <button
                  onClick={decline}
                  className="text-[0.8rem] bg-transparent hover:bg-red-500 text-red-700 font-bold hover:text-white py-[0.1rem] px-[0.3rem] border border-red-500 hover:border-transparent rounded"
                >
                  Decline
                </button>
              </>
            )} */}

            {SessionState.Established === session.state && (
              <>
                <button
                  onClick={isHeld ? unhold : hold}
                  className="btn btn-outline-primary btn-sm"
                >
                  {isHeld ? "Unhold" : "Hold"}
                </button>
                <button
                  onClick={isMuted ? unmute : mute}
                  className="btn btn-outline-primary btn-sm m-1"
                >
                  {isMuted ? "Unmute" : "Mute"}
                </button>
              </>
            )}

            {![SessionState.Terminating, SessionState.Terminated].includes(
              session.state
            ) && (
                <button
                  onClick={hangup}
                  className="btn btn-outline-danger btn-sm"
                >
                  Hang Up
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <p className="text-md leading-6 text-gray-900 font-bold">
          Duration:{" "}
          {timer?.answeredAt && (
            <CallTimer
              isEnd={session.state === SessionState.Terminated}
              startAt={timer.answeredAt}
            />
          )}
        </p>
        <div className="mt-1 flex items-center gap-x-1.5">
          <div className="flex-none rounded-full bg-emerald-500/20 p-1">
            <div className="h-1.5 w-1.5 rounded-full bg-emerald-500"></div>
          </div>
          <p className="text-xs leading-5 text-gray-500">
            {direction === SessionDirection.INCOMING
              ? "Incoming Call"
              : "Outgoing Call"}
          </p>
        </div>
      </div>
    </li>
  );
};
