import { ALL_TAGS } from "../constant/ActionType";

export default function AllGetTagsReducer(
  state = {
    alltags: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_TAGS:
      return {
        ...state,
        alltags: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
