import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import {
  can_add_teams,
  can_edit_teams,
  can_view_teams,
} from "helper/permissionsCheck";

const icons = {
  navigation: MdHome, // General Navigation icon
  allTeams: HiOutlineUserGroup, // Icon for All Teams
  addTeam: HiOutlineUserAdd, // Icon for Adding a Team
};

const teamsMenu =
  can_add_teams || can_edit_teams || can_view_teams
    ? {
      id: "teams-group",
      title: "Teams",
      icon: icons.allTeams,
      type: "group",
      children: [
        (can_add_teams || can_edit_teams || can_view_teams) && {
          id: "all-teams",
          title: "All Teams",
          type: "item",
          url: "/teams/all-teams",
          icon: icons.allTeams,
          breadcrumbs: true,
        },
        // can_add_teams && {
        //   id: "add-team",
        //   title: "Add Team",
        //   type: "item",
        //   url: "/teams/add-team",
        //   icon: icons.addTeam,
        //   breadcrumbs: true,
        // },
      ].filter(Boolean),
    }
    : null;

export default teamsMenu;
