import { SIPProvider } from "react-sipjs";
import { CallSip } from "./CallSip";
import HandlePasswordDecrypte from "helper/password-decrypte";
import { useEffect, useState } from "react";
import { getDecryptedUserData} from "../../helper/helper";

function SipjsProvider({ currentLead, allData,setLeadFieldDisabledCond }) {

    const [all_Data,setAll_Data]=useState({})
    useEffect(()=>{setAll_Data(allData)},[allData])

    const currentLoginUserJSON= getDecryptedUserData();
    let currentLoginUser=null;
    if(currentLoginUserJSON){
      currentLoginUser=JSON.parse(currentLoginUserJSON)
    }
    console.log(currentLoginUser,'currentLoginUser',currentLoginUserJSON)
    
    return (
        <div className="p">
            {all_Data?.call_provider ? <SIPProvider
                options={{
                    domain: `${all_Data?.call_provider?.server}:${all_Data?.call_provider?.port}`,
                    webSocketServer: `wss://${all_Data?.call_provider?.server}:${all_Data?.call_provider?.port}/ws`,
                    onConnectionClosed: () => {
                        console.warn("WebSocket connection closed");
                    },
                    onConnectionError: (error) => {
                        console.error("WebSocket connection error", error);
                    },
                }}
            >
               {currentLoginUser?.role_name=='agent'&& <div>
                    <CallSip setLeadFieldDisabledCond={setLeadFieldDisabledCond} username={currentLoginUser?.agent_details?.agents?.[0]?.agent_name} password={HandlePasswordDecrypte(currentLoginUser?.agent_details?.agents?.[0]?.agent_password)} callTo={'0'+currentLead?.lead?.phone?.split(' ')[1]} currentLead={currentLead} allData={all_Data} />
                </div>}
            </SIPProvider> :
                <p style={{ color: 'red' }}>No Provider Available </p>
            }
        </div>
    );
}

export default SipjsProvider;
