import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

const { Option } = Select;

const PaginatedSelect = ({
  opt,
  optKey,
  optValue,
  callApi,
  allData,
  value,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(allData?.meta?.current_page ?? 1);
  const [FilterLeadName, setFilterLeadName] = useState("");
  const [hasMore, setHasMore] = useState(
    allData?.meta?.current_page < allData?.meta?.last_page
  );

  // Fetch data when the component mounts or when the page changes
  const fetchData = async (pageNum) => {
    setLoading(true);
    callApi(setLoading, { page: pageNum,name:FilterLeadName });
  };

  const handleScroll = (event) => {
    const { target } = event;

    if (
      target.scrollTop + target.offsetHeight+2 >= target.scrollHeight &&
      hasMore &&
      !loading
    ) {
      fetchData(page + 1);
    }
  };

  const handleSearch = (value) => {
    setFilterLeadName(value)
    if(value !==""){
      callApi(setLoading, { page: 1,name:value });
    }
  };
  // Sync the initial page data with the API response
  useEffect(() => {
    if (allData?.meta?.current_page) {
      setPage(allData?.meta?.current_page);
      setHasMore(allData?.meta?.current_page < allData?.meta?.last_page);
    }
  }, [allData?.meta?.current_page]);

  // Filter out duplicate entries based on optKey or optValue
  const uniqueOptions = Array.from(
    new Map(opt.map(item => [item[optValue], item])).values()
  );

  const debouncedSearch = debounce(handleSearch, 1000);

  return (
    <Select
      mode="multiple"
      showSearch
      placeholder="Select an option"
      notFoundContent={loading ? <Spin size="small" /> : null}
      onPopupScroll={debounce(handleScroll, 300)}
      style={{ width: "100%" }}
      value={value} // This makes the Select component controlled by the Form
      onChange={onChange} // This ensures the value updates when the user selects/deselects options
      onSearch={debouncedSearch} // No need to wrap it again
      filterOption={(input, option) =>
      {
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
      }
      }
      >
      {uniqueOptions.map((item) => (
        <Option key={item[optValue]} value={item[optValue]}>
          {item[optKey]}
        </Option>
      ))}
    </Select>
  );
};

export default PaginatedSelect;
