import React, { useEffect, useState } from "react";
import { Drawer, Table } from "antd";
import { useSelector } from "react-redux";
import { LeadsDetails } from "components/columns/columns";

const DrawerLeadDetail = ({ children, loadingD }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState("large");
  const selector = useSelector((state) => state);
  const AllDetailsData =
    selector?.AllLeadDetailsDataRed?.alldataLeadDeatil ?? [];

  const showDrawer = () => {
    setOpen(true);

  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ width: "100%" }} onClick={showDrawer}>
        {children}
      </div>
      <Drawer
        title="Lead Detail"
        zIndex={10000}
        width={1200}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div className="container-fluid mt-1">
          <div className="row">
            <div className="col-md-4">
              <p>Name : {AllDetailsData?.lead?.name}</p>
            </div>
            <div className="col-md-4">
              <p>Email : {AllDetailsData?.lead?.email}</p>
            </div>
            <div className="col-md-4">
              <p>Phone : {AllDetailsData?.lead?.phone}</p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <Table
                dataSource={AllDetailsData?.lead?.activities_history ?? []}
                pagination={true}
                loading={loadingD}
                columns={LeadsDetails()}
              />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerLeadDetail;
