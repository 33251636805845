import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, List, ListItemButton, ListItemText, Collapse, Typography, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// project-imports
import NavItem from './NavItem';
import { dispatch, useSelector } from 'store';
import { activeID } from 'store/reducers/menu';

// ==============================|| NAVIGATION - GROUP ||============================== //

const NavGroup = ({ item, lastItem, remItems, lastItemId }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { drawerOpen } = useSelector((state) => state.menu);
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentItem, setCurrentItem] = useState(item);
  const [expanded, setExpanded] = useState(false); // Track the expanded state

  useEffect(() => {
    if (lastItem) {
      if (item.id === lastItemId) {
        const localItem = { ...item };
        const elements = remItems.map((ele) => ele.elements);
        localItem.children = elements.flat(1);
        setCurrentItem(localItem);
      } else {
        setCurrentItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, lastItem, downLG]);

  useEffect(() => {
    const checkSelectedOnload = (data) => {
      const childrens = data.children ? data.children : [];
      childrens.forEach((itemCheck) => {
        if (itemCheck.url === pathname) {
          dispatch(activeID(currentItem.id));
          setExpanded(true); // Automatically expand if the current item is active
        }
      });
    };

    checkSelectedOnload(currentItem);
  }, [pathname, currentItem]);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const navCollapse = currentItem.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon variant="Bulk" size={drawerOpen ? 18 : 20} /> : false;
  return (
    <>
      {item.title !== 'Dashboard' && <ListItemButton onClick={item.title !== 'Dashboard' ? handleExpandClick : null} sx={{ pl: 2 }}>
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {itemIcon} {item.title}
            </Typography>
          }
        />
        {/* Only show the expand/collapse icon if the title is not "Dashboard" */}
        {item.title !== 'Dashboard' && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>}

      {/* Only show the Collapse component if the title is not "Dashboard" */}
      {item.title !== 'Dashboard' ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navCollapse}
          </List>
        </Collapse>
      ) : <List component="div" disablePadding>
        {navCollapse}
      </List>}
    </>

  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  lastItem: PropTypes.bool,
  remItems: PropTypes.array,
  lastItemId: PropTypes.string,
};

export default NavGroup;
