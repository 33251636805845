import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import MainCard from "components/MainCard";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { Pagination, Spin } from "antd";
import { dispatch } from "store/index";
import { FetchAllCompaigns, FetchCallActivity, FetchCallManagerAllDataFetch } from "store/action/index";
import CampaignCard from "components/cards/statistics/CallManagerCard";
import { CircularProgress } from '@mui/material';


const AllCampaigns = ({ activityCall, allCallManagerdata }) => {
  const [loading, setLoading] = useState(true);
  const [params, setparams] = useState({ activity_id: 2, page: 1 });

  useEffect(() => {
    setLoading(true);
    dispatch(FetchCallManagerAllDataFetch({ params: params, setLoading: setLoading }));
  }, [params]);

  const handlePageChange = (page) => {
    setparams({ ...params, page });
  };

  console.log("sjsjsjsjsjs", allCallManagerdata?.data?.data)

  return (
    <>
      <Spin spinning={loading}>
        <div className="mb-4">
          <MainCard>
            <div style={{ display: "flex" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                  >
                    Call Manager
                  </span>
                </Grid>
              </Grid>
            </div>
          </MainCard>
        </div>

        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
            }}
          >
            {Array.isArray(allCallManagerdata?.data?.data) ? (
              allCallManagerdata?.data?.data?.map((campaign) => (
                <CampaignCard key={campaign.id} campaign={campaign} />
              ))
            ) : (
              <p>No Data</p>
            )}
          </div>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Pagination
              current={allCallManagerdata?.data?.current_page || 1}
              total={allCallManagerdata?.data?.total || 0}
              pageSize={allCallManagerdata?.data?.per_page || 10}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activityCall: state?.ActivityCallRedc?.activityCall,
    allCallManagerdata: state?.AllCallsmanagersDataReducers?.allCallManagerdata,
  };
};

export default connect(mapStateToProps)(AllCampaigns);
