import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { dispatch } from "store/index";
import { callsLogColumns } from "components/columns/columns";
import { FetchAllCallLogs, FetchGetAllLogs } from "store/action/index";

const SmsLogs = ({ alllogsdataaa }) => {
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState({ page: 1, activity_id: 4 });

    useEffect(() => {
        dispatch(FetchGetAllLogs({ params: params, setLoading: setLoading }));
    }, [params]);

    const columns = callsLogColumns({ params, setparams });

    const handlePageChange = (page) => {
        setparams({ ...params, page });
    };


    useEffect(() => {
    }, [alllogsdataaa])


    return (
        <>
            <MainCard>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        dataSource={Array.isArray(alllogsdataaa?.data) && alllogsdataaa?.data || []}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
                <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={alllogsdataaa?.current_page || 1}
                        total={alllogsdataaa?.total || 0}
                        pageSize={alllogsdataaa?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div>
            </MainCard>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alllogsdataaa: state?.GetAllLogsReducer?.alllogsdataaa,
    };
};

export default connect(mapStateToProps)(SmsLogs);