import { CASCADES_GET } from "../constant/ActionType";

export default function GetAllCasCadesReducers(
  state = {
    allCascadesData: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case CASCADES_GET:
      return {
        ...state,
        allCascadesData: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
