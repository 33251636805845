import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllWhatsappTemplates, FetchCascadeChannel } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin, Tag } from "antd";
import { FetchChannelsColumnses } from "components/columns/columns";

const FetchCascade = ({ allCascadesData }) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        dispatch(FetchCascadeChannel({ setLoading: setLoading }));
    }, [])


    return (
        <>
            <MainCard>
                <Table
                    className="border rounded"
                    style={{ marginTop: 25 }}
                    loading={loading}
                    dataSource={allCascadesData ?? []}
                    columns={FetchChannelsColumnses()}
                />
            </MainCard>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        allCascadesData: state.GetAllCasCadesReducers.allCascadesData,
    };
};

export default connect(mapStateToProps)(FetchCascade);