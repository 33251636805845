import { ALL_TEAMS } from "../../constant/ActionType";
export default function AllTeamsReducer(
  state = {
    allTeams: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_TEAMS:
      return {
        ...state,
        allTeams: action.data?.data,
        statusCode: action.status,
      };
    default:
  }
  return state;
}
