import React from 'react';

const AudioPlayer = ({ audioUrl }) => {
  return (
    <div>
      <audio controls style={{ maxWidth: '700px', marginTop: "14px" }}>
        <source src={audioUrl} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;