export const base_url = "https://alkhidmat.alisonstech-dev.com/api/api/";
// export const base_url = "http://116.0.59.126/api/api/";
// export const base_url = "http://192.168.50.77:9090/api/";
// export const base_url = "https://leads.alisonstech-dev.com/api/api/";

export const encryptionSecret = "2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP";
export const audio_url = "http://116.0.59.126/recordings/";

export const login_api_route = "login";
export const ForgetPassword = "forgot_password/sendCode";
export const ChangePassword = "password/changePassword";
export const VerifyOTP = "forgot_password/verifyCode";
export const registerRollsApi = "Role/create-role";
export const editRoleAPI = "Role/Update";
export const registerbranduser = "User/register";
export const allUsersApi = "User/get";
export const usersStatusUpdateApi = "User/update";
export const edituserapis = "User/update";
export const createUsers = "user_management/create";
export const updateUsers = "user_management/update";
export const getAllUsers = "user_management/get";
export const allRoleApi = "Role/get-roles";
export const assignpermissionsApi = "Permission/assign-permission";
export const getRolePermissionsApi = "Permission/get-rolepermissions";
export const allpermissionsApi = "Permission/get-allpermissions";
export const getAllCounts = "Dashboard/get/count";
export const compaigns_route_api = "campaigns/campaigns";
export const get_all_leads = "leads/leads";
export const leads_details = "activity-history/lead-activities/";
export const get_all_tags = "tags/tags";
export const soft_delete_tags_path = "tags/tags/";
export const all_activities = "activity/activity";
export const email_templates = "template/email-templates";
export const whatsapp_templates = "template/whatsapp-template";
export const email_setting = "settings/email-settings";
export const sms_setting = "settings/sms";
export const whtsapp_setting = "settings/whatsapp/settings";
export const call_setting = "settings/call-settings";
export const teams = "teams/teams";
export const notes_api = "note/note";
export const add_activity = "activity/add-activity";
export const campaign_activity = "campaigns/campaign-activities";
export const activity_detail = "activity/details";
export const call_script_api = "call/call-scripts";
export const call_logs_get_api = "call/call-scripts";
export const all_logs_get_api = "logs/index";
export const leadscall_logs_get_api = "logs/index";
export const call_log = "logs/call";
export const sms_logs_get_api = "call/call-scripts";
export const email_logs_get_api = "call/call-scripts";
export const whatsapp_logs_get_api = "call/call-scripts";
export const sms_templates_api = "template/sms-templates";
export const addLead_activityapi = "activity/add-leads/";
export const addCallLead_activityapi = "activity/store-answer";
export const edit_activities = "activity/update-activity/";
export const process_activities = "activity/process-activity/";
export const channel_get_api_route = "settings/whatsapp/get-channels";
export const lead_historys = "leads/history/";
export const send_email_lead_route = "omni-channel/post-message/";
export const callActivity = "activity/type";
export const dashboardcounts_api = "Dashboard/get/count";
export const fecthChannels = "settings/whatsapp/fetch-channels";
export const fetchcascades = "settings/whatsapp/get-cascades";
export const whatsapptemplatesubjidagairoute = "settings/whatsapp/fetch-templates/";
export const refreshCampaignLeads_api = "campaigns/get-fresh-leads/";
export const call_manager_route = "activity/type"
