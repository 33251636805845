import React, { useEffect, useState } from "react";
import { Table, Spin, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { EditOutlined } from "@ant-design/icons";
import IconButton from "@mui/material/IconButton";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { useSelector } from "react-redux";
import { FetchAllEmailSettings } from "store/action/index";
import { Button, Grid } from "@mui/material";
import { AllEmailSettingColumns } from "components/columns/columns";
import {
  can_edit_email_setting,
  can_add_email_setting
} from "helper/permissionsCheck";

const AllEmailSettings = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const selector = useSelector((state) => state);
  const allActivitiesData =
    selector?.AllEmailSettingReducer?.allEmailsetting?.data ?? [];
  const columns = AllEmailSettingColumns();

  columns.push(can_edit_email_setting ? {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          <IconButton
            className="mx-2"
            type="primary"
            onClick={() => {
              navigate("/settings/add-email-setting", { state: record });
            }}
          >
            <EditOutlined />
          </IconButton>
        </span>
      );
    },
  } : <></>);

  useEffect(() => {
    dispatch(FetchAllEmailSettings({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    // console.log("datatemailsetings", allActivitiesData);
  }, [allActivitiesData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Email Settings
            </span>
          </Grid>
        </Grid>
        {can_add_email_setting ? <Grid container alignItems="center" justifyContent="flex-end">
          <Link to={"/settings/add-email-setting"}>
            <Button
              disableElevation
              className="px-2"
              size="large"
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#00529D" size={26} />
              </span>
              Add Email Setting
            </Button>
          </Link>
        </Grid> : <></>}
      </div>

      <MainCard>
      <div style={{ overflowX: "auto" }}>
        <Spin spinning={loading}>
          <Table
            dataSource={allActivitiesData ?? []}
            columns={columns}
            style={{ marginTop: 25 }}
          />
        </Spin>
      </div>
      </MainCard>
    </>
  );
};

export default AllEmailSettings;
