// type
import { Home3, HomeTrendUp } from 'iconsax-react';
import { LuLayoutDashboard } from "react-icons/lu";
import {
  can_view_call_made,
} from "helper/permissionsCheck";
// icons
const icons = {
  navigation: Home3,
  dashboard: LuLayoutDashboard
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = can_view_call_made
  ? {
      id: 'group-dashboard',
      // title: 'Navigation',
      title: 'Dashboard',
      icon: icons.dashboard,
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          icon: icons.dashboard,
          breadcrumbs: false,
        },
      ],
    }
  : null;

export default dashboard;
