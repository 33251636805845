import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import {
  can_add_call_scripts,
  can_edit_call_scripts,
  can_view_call_scripts,
  can_view_call_manager,
} from "helper/permissionsCheck";

const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

const callscriptMenu =
  can_add_call_scripts ||
  can_edit_call_scripts ||
  can_view_call_scripts ||
  can_view_call_manager
    ? {
        id: "callscript-group",
        title: "Call",
        icon: icons.allLeads,
        type: "group",
        children: [
          can_view_call_scripts
            ? {
                id: "all-call-script",
                title: "All Call Script",
                type: "item",
                url: "/call-script/all-call-script",
                icon: icons.allLeads,
                breadcrumbs: true,
              }
            : null,
          can_view_call_manager
            ? {
                id: "all-call-manager",
                title: "Call Manager",
                type: "item",
                url: "/activities/call-manager",
                icon: icons.allLeads,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;

export default callscriptMenu;
