import { ALL_WHATSAPP_LOGS } from "../../constant/ActionType";

export default function AllWhatsAppLogsReducer(
    state = {
        allwhatsapplogs: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_WHATSAPP_LOGS:
            return {
                ...state,
                allwhatsapplogs: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
