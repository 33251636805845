import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Card, Button, Space, Row, Col, Modal, Spin } from 'antd';
import { useSelector } from "react-redux";
import {
    Phone,
    Globe,
    Plus,
    X
} from 'lucide-react';
import { TbVariablePlus } from "react-icons/tb";
import * as url from "../../store/constant/Endpoints";
import { Button as ButtonMui } from "@mui/material";
import { dispatch } from "store/index";
import { AddWhatsappTemplate, FetchWhatsappChannel, FetchAllWhatsappTemplates } from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
const CryptoJS = require("crypto-js");


const { TextArea } = Input;
const { Option } = Select;

const EditActivityWhatsappTemplate = ({ EditActivityTemplate, isDisabled, setHasFormBeenModified, isProgrammaticUpdate, selectedTemplate, setSelectedTemplate, MainbuttonCLicked, hasFormBeenModified, onTemplateCreated, setGetSeetingIGEre }) => {
    const [form] = Form.useForm();
    const [buttons, setButtons] = useState([]);
    const [preview, setPreview] = useState({
        headerText: '',
        text: '',
        footerText: '',
        buttons: []
    });
    const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
    const [variableCount, setVariableCount] = useState(0);
    const [variableValues, setVariableValues] = useState([]);
    const [templateValues, setTemplateValues] = useState(null);
    const [hasFilledVariables, setHasFilledVariables] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [currentFormValues, setCurrentFormValues] = useState(null);
    const [selectedProviderId, setSelectedProviderId] = useState();
    const selector = useSelector((state) => state);
    const whatsappSettingData = selector?.AllWhatsappSettingReducer?.allWhatsappsetting?.data ?? [];
    const navigate = useNavigate();
    console.log("AllValuesWhatsappCHannel", whatsappSettingData)
    const buttonTypes = [
        { key: 'URL', icon: <Globe style={{ width: '14px', marginRight: '5px' }} />, label: 'Website' },
        { key: 'PHONE', icon: <Phone style={{ width: '14px', marginRight: '5px' }} />, label: 'Call Us' },
    ];

    // Initial data fetching
    useEffect(() => {
        dispatch(FetchWhatsappChannel());
        dispatch(FetchAllWhatsappTemplates()); // Assuming you have this action
    }, []);


    // Handle template selection
    useEffect(() => {
        if (selectedTemplate) {
            // Reset form with template data
            form.setFieldsValue({
                name: selectedTemplate.name || '',
                category: 'MARKETING',
                channelType: selectedTemplate.channelType || 'WHATSAPP',
                text: selectedTemplate.content?.text || '',
                footerText: selectedTemplate.content?.footer?.text || '',
                headerText: selectedTemplate.content?.header?.text || '',
                subject: selectedTemplate.subject || '',
                subjectId: selectedTemplate.subjectId || ''
            });

            // Handle buttons if they exist
            if (selectedTemplate.content?.keyboard?.rows?.[0]?.buttons) {
                const templateButtons = selectedTemplate.content.keyboard.rows[0].buttons.map((button, index) => ({
                    id: index,
                    type: button.buttonType,
                    text: button.text,
                    value: button.buttonType === 'URL' ? button.url : button.phone
                }));
                setButtons(templateButtons);
            } else {
                setButtons([]);
            }

            updatePreview(form.getFieldsValue());
        }
    }, [selectedTemplate]);

    // Handle edit mode
    useEffect(() => {
        if (EditActivityTemplate) {
            form.setFieldsValue({
                name: EditActivityTemplate.name || '',
                category: 'MARKETING',
                channelType: EditActivityTemplate.channelType || 'WHATSAPP',
                text: EditActivityTemplate.body || '',
                footerText: EditActivityTemplate.footer || '',
                headerText: EditActivityTemplate.header?.content || '',
                subject: EditActivityTemplate.subject || '',
                subjectId: EditActivityTemplate.subjectId || ''
            });

            if (EditActivityTemplate.content?.keyboard?.rows?.[0]?.buttons) {
                const existingButtons = EditActivityTemplate.content.keyboard.rows[0].buttons.map((button, index) => ({
                    id: index,
                    type: button.buttonType,
                    text: button.text,
                    value: button.buttonType === 'URL' ? button.url : button.phone
                }));
                setButtons(existingButtons);
            }
            updatePreview(form.getFieldsValue());
        }
        // console.log("TemsdfghfdplateSelectData", EditActivityTemplate)
    }, [EditActivityTemplate]);

    // Update preview when buttons change
    useEffect(() => {
        updatePreview(form.getFieldsValue());
    }, [buttons]);

    const handleNavigate = () => {
        navigate('/activities/all-activities');
    };

    const updatePreview = (values) => {
        setPreview(prev => ({
            ...prev,
            ...(values.headerText && { headerText: values.headerText }),
            ...(values.text && { text: values.text }),
            ...(values.footerText && { footerText: values.footerText }),
            buttons: buttons
        }));
    };

    const addButton = (type) => {
        const newButton = {
            id: buttons.length,
            type,
            text: '',
            value: ''
        };
        setButtons([...buttons, newButton]);
    };

    const removeButton = (buttonId) => {
        setButtons(buttons.filter(button => button.id !== buttonId));
        updatePreview(form.getFieldsValue());
    };

    const updateButtonField = (buttonId, field, value) => {
        const updatedButtons = buttons.map(button =>
            button.id === buttonId ? { ...button, [field]: value } : button
        );
        setButtons(updatedButtons);
        updatePreview(form.getFieldsValue());
    };

    const countVariables = (text) => {
        const matches = text.match(/\{\{1\}\}/g);
        return matches ? matches.length : 0;
    };

    const handleAddVariable = () => {
        const bodyText = form.getFieldValue('text');
        if (!bodyText) {
            alert("Body Text cannot be empty.");
        } else {
            form.setFieldsValue({ text: `${bodyText} {{1}}` });
            updatePreview(form.getFieldsValue());
        }
    };

    const handleVariableModalCancel = () => {
    };

    const transformToMatcherFormat = (values, textExampleParams = []) => {
        const formattedButtons = buttons.map(button => {
            const baseButton = {
                text: button.text,
                buttonType: button.type
            };

            return button.type === 'URL'
                ? { ...baseButton, url: button.value, urlTextExample: button.value }
                : { ...baseButton, phone: button.value };
        });

        return {
            messageMatcher: {
                name: values.name,
                channelType: values.channelType,
                language: "EN",
                content: {
                    header: values.headerText ? {
                        headerType: 'TEXT',
                        text: values.headerText,
                        headerExampleTextParam: values.headerExample || ""
                    } : undefined,
                    text: values.text,
                    textExampleParams: textExampleParams,
                    keyboard: buttons.length > 0 ? {
                        rows: [{
                            buttons: formattedButtons
                        }]
                    } : undefined,
                    footer: values.footerText ? {
                        text: values.footerText
                    } : undefined
                },
                category: "MARKETING",
                type: "OPERATOR",
                subject: values.subject,
            },
            subjectIds: [values.subjectId]
        };
    };

    // const onFinish = (values) => {
    //     const count = countVariables(values.text || '');

    //     if (count > 0 || !hasFilledVariables) {
    //         setVariableCount(count);
    //         setVariableValues(new Array(count).fill(''));
    //         setTemplateValues(values);
    //         setIsVariableModalOpen(true);
    //     } else {
    //         setIsLoading(true);
    //         dispatch(AddWhatsappTemplate({
    //             matcherFormat: hasFilledVariables ? templateValues : transformToMatcherFormat(values),
    //             handleNavigate: handleNavigate
    //         }));
    //     }
    // };

    useEffect(() => {
        if (!selectedProviderId) {
            toast.error("Provider must be selected");
            return;
        }
        setGetSeetingIGEre(selectedProviderId?.id)
        console.log("Idididiididisjchecing", selectedProviderId)
    }, [selectedProviderId])

    const createNewTemplate = async (values, variables = []) => {
        try {
            setIsLoading(true);
            if (!selectedProviderId) {
                toast.error("Provider must be selected");
                return;
            }
            const matcherFormat = { ...transformToMatcherFormat(values, variables), provider_id: selectedProviderId?.subjectId };
            let getNewTokenn = localStorage.getItem("token");
            let NewToekn = "";
            if (getNewTokenn) {
                NewToekn = CryptoJS.AES.decrypt(
                    getNewTokenn,
                    url.encryptionSecret
                ).toString(CryptoJS.enc.Utf8);
            }

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${NewToekn}`,
                }
            };

            const response = await axios.post(
                `${url?.base_url}${url.whatsapp_templates}`,
                matcherFormat,
                config
            );

            setIsLoading(false);
            const templateId = response?.data?.template_id;
            if (templateId && onTemplateCreated) {
                onTemplateCreated(templateId);
            }

            return templateId;
        } catch (error) {
            console.error('Error creating template:', error);
            setIsLoading(false);
            throw error;
        }
    };

    const handleVariableModalOk = async () => {
        if (!variableValues.every(value => value)) {
            Modal.error({
                title: 'Error',
                content: 'Please fill in all variable values'
            });
            return;
        }

        try {
            await createNewTemplate(currentFormValues, variableValues);
            setIsVariableModalOpen(false);
            if (onTemplateCreated) {
                onTemplateCreated();
            }
        } catch (error) {
            console.error('Error in template creation:', error);
            Modal.error({
                title: 'Error',
                content: 'Failed to create template. Please try again.'
            });
        }
    };
    const handleFormSubmission = async (values) => {
        try {
            setIsLoading(true);
            const count = countVariables(values.text || '');

            if (count > 0) {
                // If there are variables, show the modal
                setVariableCount(count);
                setVariableValues(new Array(count).fill(''));
                setCurrentFormValues(values);
                setIsVariableModalOpen(true);
            } else {
                // If no variables, create template directly
                await createNewTemplate(values);
                if (onTemplateCreated) {
                    onTemplateCreated();
                }
            }
        } catch (error) {
            console.error('Error in form submission:', error);
            Modal.error({
                title: 'Error',
                content: 'Failed to create template. Please try again.'
            });
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (MainbuttonCLicked && hasFormBeenModified) {
            const currentValues = form.getFieldsValue();
            handleFormSubmission(currentValues).catch(error => {
                console.error('Error during form submission:', error);
            });
        }
    }, [MainbuttonCLicked, hasFormBeenModified]);

    // Return shouldNavigate status for parent component
    useEffect(() => {
        if (shouldNavigate) {
            handleNavigate();
        }
    }, [shouldNavigate]);

    const WhatsAppPreview = () => (
        <div className="bg-gray-100 p-4 rounded-lg">
            {preview.headerText && (
                <div className="font-bold text-gray-800 mb-2">
                    Character Count: {(preview?.headerText?.length || 0) +
                        (preview?.text?.length || 0) +
                        (preview?.footerText?.length || 0)}
                </div>
            )}
            <div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
                {preview.headerText && (
                    <div className="font-bold text-gray-800">{preview.headerText}</div>
                )}
                {preview.text && (
                    <div className="text-gray-700 whitespace-pre-wrap">{preview.text}</div>
                )}
                {preview.footerText && (
                    <div className="text-sm text-gray-500">{preview.footerText}</div>
                )}
                {buttons.length > 0 && (
                    <div className="space-y-2 d-flex flex-column pt-2 border-t">
                        {buttons.map((button) => (
                            <Button
                                key={button.id}
                                className="w-full bg-blue-600 m-1 rounded"
                            >
                                {button.text || `New ${button.type} Button`}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <>
            <Spin spinning={isLoading}>
                <Row gutter={24} className="p-4">
                    <Col span={14}>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={(changedValues, allValues) => {
                                updatePreview(allValues);
                                if (!isProgrammaticUpdate) {
                                    const modifiedFields = Object.keys(changedValues);
                                    const trackableFields = ['templateName', 'subject', 'body', 'footer', 'headerText', 'text', 'footerText'];
                                    if (modifiedFields.some(field => trackableFields.includes(field))) {
                                        setHasFormBeenModified(true);
                                    }
                                }
                            }}
                            initialValues={{
                                category: 'MARKETING',
                                channelType: 'WHATSAPP'
                            }}
                        >
                            <Card className="mb-4">

                                <Form.Item
                                    name="name"
                                    label="Template Name"
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder="Enter template name" disabled={isDisabled} />
                                </Form.Item>
                                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                                    <Form.Item
                                        name="provider_id"
                                        label=""
                                        rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Provider"
                                            style={{ width: 300 }}
                                            value={selectedProviderId ? selectedProviderId.id : undefined} 
                                            disabled={isDisabled}
                                            onChange={(selectedProviderId) => {
                                                const selectedProviderObj = whatsappSettingData.find(
                                                    (setting) => setting.id === selectedProviderId
                                                ); 
                                                setSelectedProviderId(selectedProviderObj); 
                                            }}>
                                            {Array.isArray(whatsappSettingData) && whatsappSettingData?.length !== 0 && whatsappSettingData?.map((setting) => (
                                                <Option key={setting.id} value={setting.id}> 
                                                    {setting.sender_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </div>
                                <Form.Item name="channelType" label="Channel Type" hidden>
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item name="subject" label="Subject" hidden>
                                    <Input disabled />
                                </Form.Item>

                                <Form.Item name="subjectId" label="Subject ID" hidden>
                                    <Input disabled />
                                </Form.Item>
                            </Card>

                            <Card title="Message Content" className="mb-4">
                                <Form.Item name="headerText" label="Header Text">
                                    <Input placeholder="Example: Your company {{1}}" disabled={isDisabled} />
                                </Form.Item>

                                <Form.Item
                                    name="text"
                                    label="Body Text"
                                    rules={[{ required: true }]}
                                >
                                    <TextArea
                                        rows={4}
                                        placeholder="Example: Hello, {{1}}! Thanks for choosing {{2}}"
                                        disabled={isDisabled}
                                    />
                                </Form.Item>

                                <ButtonMui
                                    onClick={handleAddVariable}
                                    title='Add Variable'
                                    variant='contained'
                                    className='my-2'
                                >
                                    <TbVariablePlus />
                                </ButtonMui>

                                <Form.Item name="footerText" label="Footer Text">
                                    <Input placeholder="Add a footer note" disabled={isDisabled} />
                                </Form.Item>
                            </Card>

                            <Card title="Buttons" className="mb-4">
                                <div className="grid grid-cols-12 gap-2 mb-4 d-flex justify-content-center">
                                    {buttonTypes.map(({ key, icon, label }) => (
                                        <Button
                                            key={key}
                                            onClick={() => addButton(key)}
                                            className="text-left grid-cols-12 flex items-center m-1 gap-4"
                                            disabled={isDisabled || buttons.length >= 3}
                                            style={{ width: '45%' }}
                                        >
                                            {icon}
                                            <span className="pl-4">{label}</span>
                                        </Button>
                                    ))}
                                </div>

                                <Space direction="vertical" className="w-full">
                                    {buttons.map((button) => (
                                        <Card
                                            key={button.id}
                                            size="small"
                                            className="w-full"
                                            title={button.type === 'URL' ? "Link" : "Phone"}
                                            extra={
                                                <Button
                                                    type="text"
                                                    onClick={() => removeButton(button.id)}
                                                    className="p-0"
                                                    disabled={isDisabled}
                                                >
                                                    <X className="h-4 w-4" />
                                                </Button>
                                            }
                                        >
                                            <div className="space-y-4">
                                                <Input
                                                    placeholder="Button Text"
                                                    value={button.text}
                                                    onChange={(e) => updateButtonField(button.id, 'text', e.target.value)}
                                                    disabled={isDisabled}

                                                />

                                                <Input
                                                    placeholder={button.type === 'URL' ? 'Website URL' : 'Phone Number'}
                                                    value={button.value}
                                                    onChange={(e) => updateButtonField(button.id, 'value', e.target.value)}
                                                    disabled={isDisabled}
                                                    className='mt-2'
                                                    type={button.type === 'URL' ? 'url' : 'text'}
                                                    requir
                                                />
                                            </div>
                                        </Card>
                                    ))}
                                </Space>
                            </Card>
                        </Form>
                    </Col>

                    <Col span={10}>
                        <Card title="Template Preview">
                            <WhatsAppPreview />
                        </Card>
                    </Col>
                </Row>
            </Spin>
            <Modal
                title="Enter Variable Values"
                open={isVariableModalOpen}
                onOk={handleVariableModalOk}
                onCancel={() => setIsVariableModalOpen(false)}
                destroyOnClose={false}
                maskClosable={false}
            >
                <div className="space-y-4">
                    <p>Found {variableCount} variables in your template. Please provide values for each:</p>
                    {Array.from({ length: variableCount }).map((_, index) => (
                        <Form.Item
                            key={index}
                            label={`Variable ${index + 1}`}
                            className="mb-4"
                        >
                            <Input
                                placeholder={`Enter value for variable ${index + 1}`}
                                value={variableValues[index]}
                                onChange={(e) => {
                                    const newValues = [...variableValues];
                                    newValues[index] = e.target.value;
                                    setVariableValues(newValues);
                                }}
                            />
                        </Form.Item>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default EditActivityWhatsappTemplate;
