import { ALL_EMAIL_SETTING } from "../../constant/ActionType";

export default function AllEmailSettingReducer(
    state = {
        allEmailsetting: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_EMAIL_SETTING:
            return {
                ...state,
                allEmailsetting: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
