import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Spin } from "antd";
import MainCard from "components/MainCard";
import CustomInput from "components/inputs/CustomInput";
import { allFieldsLead } from "components/all-forms-fields/all-forms-fields";
import { FetchGetAllTags, CreateLeads, FetchAllLeads, UpdateLeads } from "store/action/index";
import { dispatch } from "store/index";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CountryCodeDropdown from "../../components/inputs/countrycodedropdown"
const AddLeads = ({ allTagsData }) => {
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [loadinBtn, setLoadingBtn] = useState(false)



  useEffect(() => {
    dispatch(FetchAllLeads({ setLoading: setIsLoading }));
    if (location?.state?.id) {
      setIsEditMode(true);
    }
  }, [location?.state]);

  useEffect(() => {
    if (!isLoading && isEditMode && location?.state) {
      const formValues = allFieldsLead().reduce((acc, field) => {
        acc[field.name] = location?.state?.[field.name] || (field.type === 'select' && field.multiple ? [] : "");
        return acc;
      }, {});

      form.setFieldsValue(formValues);
    }
  }, [isLoading, isEditMode, location?.state, form]);


  useEffect(() => {
    dispatch(FetchGetAllTags({ setLoading: setIsLoading }));
  }, []);

  const navigateToAllLead = () => {
    navigate("/leads/all-leads");
  };

  const onFinish = (values) => {
    setLoadingBtn(true)
    if (isEditMode) {
      dispatch(
        UpdateLeads({
          fieldData: {
            ...values,
            _method: "PUT",
            id: location?.state?.id,
          },
          setLoading: setIsLoading,
          isNavigate: navigateToAllLead,
          setLoadingBtn: setLoadingBtn

        })
      );
    } else {
      dispatch(
        CreateLeads({
          setLoading: setIsLoading,
          fieldData: values,
          isNavigate: navigateToAllLead,
          setLoadingBtn: setLoadingBtn
        })
      );
    }
  };


  const tag = Array.isArray(allTagsData)
    ? allTagsData.map((v) => ({ label: v.name, value: v.id }))
    : [];

  const onCountrySelect = (selectedCountryCode) => {
    form.setFieldsValue({ phone: selectedCountryCode.value }); 
  };

  return (
    <MainCard>
      <Spin spinning={isLoading}>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            {allFieldsLead(tag, onCountrySelect).map((field, index) => (
              <Col span={12} key={index}>
                <CustomInput {...field} />
              </Col>
            ))}
          </Row>

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loadinBtn}>
              {isEditMode ? "Update Lead" : " Add Lead"}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    allTagsData: state?.AllGetTagsReducer?.alltags?.data,
  };
};
export default connect(mapStateToProps)(AddLeads);
