import { FETCH_TEMPLATEWHATSAPP_SUBJECTID } from "../constant/ActionType";

export default function UpdateReducerWhatsappTemplateSUbjid(
    state = {
        updatedwhatsappTemplate: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case FETCH_TEMPLATEWHATSAPP_SUBJECTID:
            return {
                ...state,
                updatedwhatsappTemplate: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
