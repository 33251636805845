// import React, { useState, useRef } from "react";
// import { Space, Input, Select } from "antd";
// import { IconButton } from "@mui/material";
// import { CloseSquare, SearchNormal1 } from "iconsax-react";
// import { SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";

// const { Option } = Select;

// const GetColumnSearchProps = (dataIndex, params, setParams, statusValues) => {
//   const [searchText, setSearchText] = useState("");
//   const [searchedColumn, setSearchedColumn] = useState("");
//   const searchInput = useRef(null);

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     setParams({ ...params, [dataIndex]: selectedKeys[0] ?? "", page: 1 });
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const handleReset = (clearFilters, close, dataIndex) => {
//     clearFilters();
//     setSearchText("");
//     setSearchedColumn("");
//     setParams({ ...params, [dataIndex]: "", page: 1 });
//     close();
//   };

//   return {
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//       close,
//     }) => {
//       return (
//         <div style={{ padding: 10 }}>
//           {dataIndex === "status" ? (
//             <Select
//               ref={searchInput}
//               placeholder={`Select Status`}
//               value={selectedKeys[0]}
//               onChange={(value) => setSelectedKeys(value ? [value] : [])}
//               style={{ display: "block", width: "100%" }}
//             >
//               {statusValues.map((status, index) => (
//                 <Option
//                   key={status}
//                   value={status}
//                   style={{ marginTop: index === 0 ? "45px" : "" }}
//                 >
//                   {status === 1 ? "Active" : "Inactive"}
//                 </Option>
//               ))}
//             </Select>
//           ) : (
//             <Input
//               ref={searchInput}
//               placeholder={`Search`}
//               value={selectedKeys[0]}
//               onChange={(e) =>
//                 setSelectedKeys(e.target.value ? [e.target.value] : [])
//               }
//               onPressEnter={() =>
//                 handleSearch(selectedKeys, confirm, dataIndex)
//               }
//               style={{ display: "block" }}
//             />
//           )}
//           <Space className="searchButtons">
//             <IconButton
//               className="click"
//               type="primary"
//               onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//               icon={<SearchOutlined />}
//               variant="outlined"
//               size="small"
//               style={{ width: 30, height: 30 }}
//             >
//               <SearchNormal1 variant="Bulk" color="#0B3D58" />
//             </IconButton>
//             {/* <IconButton
//               className="click"
//               onClick={() => clearFilters && handleReset(clearFilters, close, dataIndex)}
//               size="small"
//               variant="outlined"
//               style={{ width: 30, height: 30, backgroundColor: "#DBE0E5" }}
//             >
//               <Refresh variant="Bulk" color="#0B3D58" />
//             </IconButton> */}
//             <IconButton
//               className="click"
//               type="link"
//               size="small"
//               style={{ width: 30, height: 30, backgroundColor: "#DBE0E5" }}
//               variant="outlined"
//               onClick={() =>
//                 clearFilters && handleReset(clearFilters, close, dataIndex)
//               }
//             >
//               <CloseSquare variant="Bulk" color="#a61611" />
//             </IconButton>
//           </Space>
//         </div>
//       );
//     },
//     filterIcon: (filtered) => (
//       <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
//     ),
//     onFilterDropdownOpenChange: (visible) => {
//       if (visible) {
//         setTimeout(() => searchInput.current?.focus(), 100);
//       }
//     },
//     render: (text) => {
//       return searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
//           searchWords={[searchText]}
//           autoEscape={true}
//           textToHighlight={text ? text.toString() : ""}
//         />
//       ) : (
//         text
//       );
//     },
//   };
// };

// export default GetColumnSearchProps;

// import React, { useEffect, useState,useRef } from "react";
// import IconButton from "@mui/material/IconButton";
// import {
//   Button,
//   Drawer,
//   Space,
//   Form,
//   Input,
//   Steps,
//   Select,
//   DatePicker,
// } from "antd";
// import moment from 'moment';
// import { SendOutlined, SearchOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
// import { SearchNormal1, CloseSquare } from "iconsax-react";

// const { RangePicker } = DatePicker;

// const GetColumnSearchProps = (dataIndex, params, setParams) => {
//   const [searchText, setSearchText] = useState('');
//   const [searchedColumn, setSearchedColumn] = useState('');
//   const searchInput = useRef(null);

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     let searchParams = {};
//     if (dataIndex === 'start_date' || dataIndex === 'end_date') {
//       const [start, end] = selectedKeys;
//       searchParams = {
//         [dataIndex]: start ? moment(start).format('YYYY-MM-DD') : '',
//         // [dataIndex + '_to']: end ? moment(end).format('YYYY-MM-DD') : '',
//         page: 1,
//       };
//     } else {
//       searchParams = { [dataIndex]: selectedKeys[0] ?? '', page: 1 };
//     }
//     setParams({ ...params, ...searchParams });
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const handleReset = (clearFilters, close, dataIndex) => {
//     clearFilters();
//     setSearchText('');
//     setSearchedColumn('');
//     setParams({ ...params, [dataIndex + '_from']: '', [dataIndex + '_to']: '', page: 1 });
//     close();
//   };

//   return {
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//       close,
//     }) => {
//       return (
//         <div style={{ padding: 10 }}>
//           {dataIndex === 'start_date' || dataIndex === 'end_date' ? (
//         <RangePicker
//         ref={searchInput}
//         value={[
//           selectedKeys[0] ? moment(selectedKeys[0]) : null,
//           selectedKeys[1] ? moment(selectedKeys[1]) : null,
//         ]}
//         onChange={(dates) => {
//           const [start, end] = dates || [];
//           setSelectedKeys(start && end ? [start, end] : []);
//         }}
//         style={{ display: "block", width: "100%" }}
//       />
      
//           ) : (
//             <Input
//               ref={searchInput}
//               placeholder={`Search`}
//               value={selectedKeys[0]}
//               onChange={(e) =>
//                 setSelectedKeys(e.target.value ? [e.target.value] : [])
//               }
//               onPressEnter={() =>
//                 handleSearch(selectedKeys, confirm, dataIndex)
//               }
//               style={{ display: 'block' }}
//             />
//           )}
//           <Space className="searchButtons">
//             <IconButton
//               className="click"
//               type="primary"
//               onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//               icon={<SearchOutlined />}
//               variant="outlined"
//               size="small"
//               style={{ width: 30, height: 30 }}
//             >
//               <SearchNormal1 variant="Bulk" color="#0B3D58" />
//             </IconButton>
//             <IconButton
//               className="click"
//               onClick={() =>
//                 clearFilters && handleReset(clearFilters, close, dataIndex)
//               }
//               size="small"
//               variant="outlined"
//               style={{ width: 30, height: 30, backgroundColor: '#DBE0E5' }}
//             >
//               <CloseSquare variant="Bulk" color="#a61611" />
//             </IconButton>
//           </Space>
//         </div>
//       );
//     },
//     filterIcon: (filtered) => (
//       <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
//     ),
//     onFilterDropdownOpenChange: (visible) => {
//       if (visible) {
//         setTimeout(() => searchInput.current?.focus(), 100);
//       }
//     },
//     render: (text) => {
//       console.log("Rendering column data:", { text, type: typeof text });
//       console.log("Current searchText:", searchText);
//       console.log("Current searchedColumn:", searchedColumn);
    
//       // Ensure searchText is always a string
//       let formattedSearchText = "";
//       if (moment.isMoment(searchText) || searchText instanceof Date) {
//         formattedSearchText = moment(searchText).format("YYYY-MM-DD");
//       } else if (typeof searchText === "string") {
//         formattedSearchText = searchText;
//       } else {
//         formattedSearchText = ""; // fallback for invalid searchText
//       }
    
//       let displayText = "";
    
//       try {
//         // Handle arrays (e.g., date ranges)
//         if (Array.isArray(text)) {
//           displayText = text
//             .map((item) =>
//               item ? moment(item).format("YYYY-MM-DD") : "Invalid Date"
//             )
//             .join(" to ");
//         }
//         // Handle moment objects or Date objects
//         else if (moment.isMoment(text) || text instanceof Date) {
//           displayText = moment(text).format("YYYY-MM-DD");
//         }
//         // Handle undefined or null values
//         else if (text === undefined || text === null) {
//           displayText = ""; // Default to an empty string
//         }
//         // Handle strings or numbers
//         else if (typeof text === "string" || typeof text === "number") {
//           displayText = text.toString(); // Ensure it's a string
//         } else {
//           // Catch any unexpected types
//           console.warn("Unexpected data type for text:", text);
//           displayText = "Invalid Data";
//         }
//       } catch (error) {
//         console.error("Error in render function:", error);
//         displayText = "Invalid Data"; // Fallback for unexpected errors
//       }
    
//       // Ensure Highlighter only receives valid strings
//       if (typeof displayText !== "string") {
//         console.warn("Non-string value passed to Highlighter:", displayText);
//         displayText = "";
//       }
    
//       // Pass the formatted string to Highlighter
//       return searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
//           searchWords={[formattedSearchText]} // Ensure searchText is a string
//           autoEscape={true}
//           textToHighlight={displayText} // Ensure it's always a string
//         />
//       ) : (
//         displayText
//       );
//     },
    
    
    
//   };
// };

// export default GetColumnSearchProps;
import React, { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SearchNormal1, CloseSquare } from "iconsax-react";
import { DatePicker } from "@mui/x-date-pickers"; // Import MUI DatePicker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // MUI Dayjs adapter
import dayjs from "dayjs"; // Import dayjs for date formatting

const GetColumnSearchProps = (dataIndex, params, setParams) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedDate, setSelectedDate] = useState(null); // State to track the selected date
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let searchParams = {};

    // Handle single date selection for start_date or end_date
    if (dataIndex === 'start_date' || dataIndex === 'end_date') {
      const selectedDate = selectedKeys[0];
      searchParams = {
        [dataIndex]: selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : '',
        page: 1,
      };
    } else {
      // Handle search for other columns
      searchParams = { [dataIndex]: selectedKeys[0] ?? '', page: 1 };
    }
    setParams({ ...params, ...searchParams });
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, close, dataIndex) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
    setSelectedDate(null); // Reset the selected date as well
    setParams({ ...params, [dataIndex]: '', page: 1 });
    close();
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const handleDateChange = (date) => {
        setSelectedDate(date); // Set the selected date
        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
        // Directly update setParams when the date is selected
        setParams({ ...params, [dataIndex]: formattedDate, page: 1 });
        setSelectedKeys(date ? [date] : []); // Update selectedKeys with the date
      };

      return (
        <div style={{ padding: 10 }}>
          {dataIndex === 'start_date' || dataIndex === 'end_date' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedKeys[0] ? dayjs(selectedKeys[0]) : null} // Ensure the date is displayed
                onChange={handleDateChange} // Directly setParams when a date is selected
                style={{ display: "block", width: "100%" }}
              />
            </LocalizationProvider>
          ) : (
            <Input
              ref={searchInput}
              placeholder={`Search`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ display: 'block' }}
            />
          )}
          <Space className="searchButtons">
            <IconButton
              className="click"
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              variant="outlined"
              size="small"
              style={{ width: 30, height: 30 }}
            >
              <SearchNormal1 variant="Bulk" color="#0B3D58" />
            </IconButton>
            <IconButton
              className="click"
              onClick={() =>
                clearFilters && handleReset(clearFilters, close, dataIndex)
              }
              size="small"
              variant="outlined"
              style={{ width: 30, height: 30, backgroundColor: '#DBE0E5' }}
            >
              <CloseSquare variant="Bulk" color="#a61611" />
            </IconButton>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.focus(), 100);
      }
    },
    render: (text) => {
      let formattedSearchText = "";

      if (dayjs(searchText).isValid()) {
        formattedSearchText = dayjs(searchText).format("YYYY-MM-DD");
      } else if (typeof searchText === "string") {
        formattedSearchText = searchText;
      } else {
        formattedSearchText = "";
      }

      let displayText = "";

      try {
        if (Array.isArray(text)) {
          displayText = text
            .map((item) =>
              item ? dayjs(item).format("YYYY-MM-DD") : "Invalid Date"
            )
            .join(" to ");
        } else if (dayjs(text).isValid()) {
          displayText = dayjs(text).format("YYYY-MM-DD");
        } else if (text === undefined || text === null) {
          displayText = "";
        } else if (typeof text === "string" || typeof text === "number") {
          displayText = text.toString();
        } else {
          displayText = "Invalid Data";
        }
      } catch (error) {
        displayText = "Invalid Data";
      }

      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[formattedSearchText]}
          autoEscape={true}
          textToHighlight={displayText}
        />
      ) : (
        displayText
      );
    },
  };
};

export default GetColumnSearchProps;

