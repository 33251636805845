import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllWhatsappTemplates, FetchPostingChannel } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin, Tag } from "antd";
import { FetchChannelColumnses } from "components/columns/columns";

const FetchChannel = ({ allwhatsappchannel }) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        dispatch(FetchPostingChannel({ setLoading: setLoading }));
    }, [])

    return (
        <>
            <MainCard>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        className="border rounded"
                        style={{ marginTop: 25 }}
                        loading={loading}
                        dataSource={allwhatsappchannel ?? []}
                        columns={FetchChannelColumnses()}
                    />
                </div>
            </MainCard>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        allwhatsappchannel: state.AllWhatsappChannnel.allwhatsappchannel,
    };
};

export default connect(mapStateToProps)(FetchChannel);