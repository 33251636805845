import { CREATE_WHATSAPP_SETTING } from "../../constant/ActionType";

export default function AllWhatsappSettingReducer(
    state = {
        allWhatsappsetting: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case CREATE_WHATSAPP_SETTING:
            return {
                ...state,
                allWhatsappsetting: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}