import { ALL_SMS_SETTING } from "../constant/ActionType";

export default function AllSmsDataReducer(
  state = {
    allSmssetting: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_SMS_SETTING:
      return {
        ...state,
        allSmssetting: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
