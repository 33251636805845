import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  can_edit_sms_setting,
  can_add_sms_setting
} from "helper/permissionsCheck";
import { AllSmsSettingColumns } from "components/columns/columns";

import { dispatch } from "store/index";
import { FetchSmSSettings } from "store/action/index";

// ==============================|| SMS SETTINGS - MANAGEMENT ||============================== //

const AllSmsSetting = ({allSmssetting}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(FetchSmSSettings({setLoading:setLoading}));
}, [])

useEffect(()=>{
},[allSmssetting])



  const columns = AllSmsSettingColumns();

  columns.push(true ? {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
        return (
            <span>
                <IconButton
                    className="mx-2"
                    type="primary"
                    onClick={() => {
                        navigate("/settings/add-sms-setting", { state: record });
                    }}
                >
                    <EditOutlined />
                </IconButton>
            </span>
        );
    },
} : <></>);


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All SMS Settings
            </span>
          </Grid>
        </Grid>
        {can_add_sms_setting ? <Grid container alignItems="center" justifyContent="flex-end">
          <Link to={"/settings/add-sms-setting"}>
            <Button
              disableElevation
              className="px-2"
              size="large"
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#00529D" size={26} />
              </span>{" "}
              Add SMS Setting
            </Button>
          </Link>
        </Grid> : <></>}
      </div>

      <MainCard>
      <div style={{ overflowX: "auto" }}>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={allSmssetting?.settings ?? []}
            columns={columns}
          />
        </Spin>
      </div>
      </MainCard>
    </>
  );
};





const mapStateToProps = (state) => {
  return {
    allSmssetting: state.AllSmsDataReducer.allSmssetting,

  };
};

export default connect(mapStateToProps)(AllSmsSetting);