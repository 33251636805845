import { ACTIVITY_LEAD_NOTES } from "../../constant/ActionType";

export default function ActivityLeadsNotesRedc(
    state = {
        activityLeadsNotesall: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ACTIVITY_LEAD_NOTES:
            return {
                ...state,
                activityLeadsNotesall: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
