import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { FetchCreateAllTags, FetchEditTags } from "store/action/index";
import { dispatch } from "store/index";

const TagModal = ({ initialValues, children, setLoading }) => {
  const [visible, setModalVisible] = useState(false);
  const [loadinBtn, setLoadingBtn] = useState(false)

  const [form] = Form.useForm();

  const handleOpen = (record) => {
    setModalVisible(true);
  };
  const handleClose = (record) => {
    setModalVisible(false);
  };

  const onFinish = (fieldData) => {
    setLoadingBtn(true)
    if (initialValues) {
      dispatch(
        FetchEditTags({
          setLoading: setLoading,
          setLoadingBtn: setLoadingBtn,
          fieldData: { ...fieldData, _method: "PUT", id: initialValues.id },
        })
      );
    } else {
      dispatch(
        FetchCreateAllTags({ setLoading: setLoading, fieldData: fieldData, setLoadingBtn: setLoadingBtn })
      );
    }
    handleClose();
  };

  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      <Modal
        title={initialValues?.name ? "Edit Tag" : "Add Tag"}
        visible={visible}
        onCancel={handleClose}
        footer={null}
      >
        <div className="row mt-4">
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 3,
            }}
            style={{
              maxWidth: "100%",
            }}
            initialValues={initialValues}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input the tag name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Color"
              name="color"
              rules={[
                {
                  required: true,
                  message: "Please select a color!",
                },
              ]}
            >
              <Input type="color" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 9,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "10px" }}
              >
                {loadinBtn ? "Processing..." : (initialValues?.name ? "Update Tag" : "Add Tag")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default TagModal;
