import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { dispatch } from "store/index";
import { Spin } from 'antd';
import { useMediaQuery } from '@mui/material';
import {
  FetchAllCallScript,
  FetchEditActivity,
  FetchCallActivity,
  FetchActivityDetails,
  GetAllUsers,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Modal, Form, Input, Descriptions, Empty } from "antd";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeads, EditActivityLeadsLogs } from "components/columns/columns";
import AddCallScriptPage from "pages/callscript/AddCallScriptPage";
import EditableFormFields from "./AddMultiQuestion";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import { FetchAllCallSettings } from "store/action/index";
import axios from 'axios';
import * as url from "../../store/constant/Endpoints"
const CryptoJS = require("crypto-js");


const { Option } = Select;
const { Title } = Typography;

const CallActivityEdit = ({
  allCallscript,
  allTeamMembers,
  activityDetail,
  activityCall,
  callActivitySetting
}) => {
  const theme = useTheme();

  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const grey200 = theme.palette.secondary[200];
  const backColor = theme.palette.background.paper;
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [templateOptions, setTemplateOptions] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false)

  const [selectedTemplate, setSelectedTemplate] = useState(
    location?.state?.data?.template_id ?? null
  );
  const [EditActivityTemplate, setEditActivityTemplate] = useState({});
  const [leadsDataSource, setLeadsDataSource] = useState([]);
  const [agent, setAgent] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([
  ]);
  const [editorValue, setEditorValue] = useState(EditActivityTemplate?.name ?? '');
  const [scriptName, setScriptName] = useState(EditActivityTemplate?.description ?? '')
  const [loading, setLoading] = useState(false)
  const [selectedSettingId, setSelectedSettingId] = useState(null)
  const [agentOptions, setAgentOptions] = useState([])
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(FetchAllCallScript({ setLoading: setLoading, params: {} }));
    dispatch(GetAllUsers({ setLoading }));
    // dispatch(FetchActivityDetails({ id: location.state?.data?.activity_id_specific }));
    let params = { id: location.state?.data?.activity_id_specific }
    dispatch(FetchCallActivity({ params: { ...params, activity_id: 2, } }));
  }, []);
  useEffect(() => {
    dispatch(FetchAllCallSettings({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    if (Array.isArray(allCallscript.data)) {
      setTemplateOptions(allCallscript.data.map(v => ({ ...v, name: v.script })));
    }
  }, [allCallscript]);

  useEffect(() => {
    if (activityCall) {
      setLeadsDataSource([
        ...(location?.state?.data?.leads || []),
        ...(activityCall?.data?.data?.[0]?.leads || [])
      ]);
      setAgent(activityCall?.data?.data?.[0]?.agents ? activityCall?.data?.data?.[0]?.agents.map(v => v.id) : [])
      setSelectedSettingId(activityCall?.data?.data?.[0]?.call_provider?.id)
    }
  }, [activityCall]);

  useEffect(() => {
    if (Array.isArray(allTeamMembers?.users)) {

      setAgentOptions(allTeamMembers?.users.map(v => ({ label: v?.name, value: v?.id })))
    } else {
      setAgentOptions([])
    }

  }, [allTeamMembers])

  const handleTemplateChange = (value) => {
    setSelectedTemplate(value);
  };

  useEffect(() => {
    if (selectedTemplate && templateOptions?.length !== 0) {
      let filterTemplate = templateOptions?.filter(
        (e) => e?.id === selectedTemplate
      );
      setEditActivityTemplate(filterTemplate[0]);

    }
  }, [selectedTemplate, templateOptions]);

  useEffect(() => {
  }, [activityCall])

  useEffect(() => {
    form.setFieldsValue({
      script: EditActivityTemplate?.name,
      description: EditActivityTemplate?.description
    })
    setEditorValue(EditActivityTemplate?.description)
  }, [EditActivityTemplate])

  const handleNavigate = () => {
    navigate(-1);
  };

  const validateAdditionalFields = (fields) => {
    const errors = fields.map((field, index) => {
      const error = {};
      if (!field?.name) {
        error.name = `Question ${index + 1} is required.`;
      }
      if (!field?.type) {
        error.type = `Type for Question ${index + 1} is required.`;
      }
      if (field?.type !== "Text" && (!field.values || field.values.length === 0)) {
        error.values = `Values are required for Question ${index + 1}.`;
      }
      return error;
    });

    const valid = errors.every(err => Object.keys(err).length === 0);
    return { valid, errors };
  };
  const HandleSave = async (status_id) => {

    try {

      const { valid, errors } = validateAdditionalFields(additionalFields);
      if (!valid) {
        errors.forEach((error, index) => {
          if (error.name) toast.error(error.name);
          if (error?.type) toast.error(error?.type);
          if (error.values) toast.error(error.values);
        });
        return;
      }
      const val = await form.validateFields();
      if (selectedTemplate && EditActivityTemplate?.name === val?.name && val?.description === EditActivityTemplate?.description) {

        if (status_id === 1) {
          if (!selectedTemplate) {
            toast.error("Template must be selected");
            return;
          }
          if (!selectedSettingId) {
            toast.error("Provider must be selected");
            return;
          }
        }

        const payload = {
          activity_id: location?.state?.data?.activity_id_specific,
          campaign_id: location?.state?.data?.campaign_id,
          template_id: selectedTemplate,
          status: status_id,
          agent,
          additionalFields,
          type: 'Call',
          setting_id: selectedSettingId
        };
        setLoadingBtn(true)

        dispatch(FetchEditActivity({ fieldData: payload, isNavigate: handleNavigate, isCall: true, setLoadingBtn: setLoadingBtn }));
      } else {
        try {

          const payload = {
            script: val.script,
            description: val?.description
          };

          let getNewTokenn = localStorage.getItem("token");
          let NewToekn = "";
          if (getNewTokenn) {
            NewToekn = CryptoJS.AES.decrypt(
              getNewTokenn,
              url.encryptionSecret
            ).toString(CryptoJS.enc.Utf8);
          }
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${NewToekn}`,
            },
          };

          const response = await axios.post(
            `${url?.base_url}${url.call_script_api}`,
            payload, config
          );

          const responseData = response.data;
          if (responseData?.data) {
            const payloadCall = {
              activity_id: location?.state?.data?.activity_id_specific,
              campaign_id: location?.state?.data?.campaign_id,
              template_id: responseData?.data?.id,
              status: status_id,
              agent,
              additionalFields,
              setting_id: selectedSettingId
            };
            setLoadingBtn(true)

            dispatch(FetchEditActivity({ fieldData: payloadCall, isNavigate: handleNavigate, isCall: true, setLoadingBtn: setLoadingBtn }));
          }
        } catch (error) {
          console.error('Error creating new template:', error);
        }
      }
      // console.log(val, 'validate')

    } catch (error) {
      console.log(error)
      if (error?.errorFields) {
        error?.errorFields?.forEach(v => {

          toast.error(v?.errors?.[0])
        })
      }
    }


  };

  const STATUS = location.state?.data?.status;

  const getActivityHistory = () => {
    if (activityCall?.data?.data && Array.isArray(activityCall?.data?.data) && activityCall?.data?.data?.length > 0) {
      return activityCall.data?.data[0]?.activity_history || [];
    }
    return [];
  };
  const expandedRowRender = (record) => {
    return (
      <div>
        <Descriptions title="Call Answers" bordered column={1}>
          {Array.isArray(record.call_answer) && record.call_answer.length ? record.call_answer.map((item) => (
            <Descriptions.Item key={item.id} label={item.question.question}>
              {(() => {
                try {
                  if (item.answer.includes('undefined')) {
                    return 'No Answer'
                  }
                  const parsedAnswer = JSON.parse(item.answer);
                  return Array.isArray(parsedAnswer)
                    ? parsedAnswer.join(', ')
                    : parsedAnswer;
                } catch {
                  return item.answer;
                }
              })()}
            </Descriptions.Item>
          )) : <Empty />}
        </Descriptions>
        <Descriptions title="Call Logs" bordered column={1}>
          {Array.isArray(record.call_log) && record.call_log.length ? record.call_log.map((log) => (
            <Descriptions.Item key={log.id}  >
              <div>
                <div>
                  <strong>Status:</strong> {log.call_status}
                </div>
                {log.call_status !== 'Terminated' && <div>
                  < strong > Started On:</strong> {log.call_started_on}
                </div>}
                <div>
                  <strong>Ended On:</strong> {log.call_ended_on}
                </div>
              </div>
            </Descriptions.Item>
          )) : <Empty />}
        </Descriptions>
      </div >
    );
  };


  const [series, setseries] = useState([]);
  const [series2, setSeries2] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [barChartOptions, setBarChartOptions] = useState({
    chart: { type: 'bar', height: 200, width: '100%' },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
      },
    },
    xaxis: { categories: [] },
    title: { text: '' },
    colors: [theme.palette.primary.main],
  });

  const [barChartSeries, setBarChartSeries] = useState([]);
  useEffect(() => {
    const rawData = activityCall?.data?.questionsAnswerPoll;
    // console.log("ActivssseCallData", rawData);

    if (rawData) {
      const transformedData = rawData.map((item) => ({
        question: item.question,
        options: Object.entries(item.options).map(([option, count]) => ({
          option,
          count,
        })),
      }));

      setQuestionsData(transformedData)
    }
  }, [activityCall]);

  useEffect(() => {
    const updateSeries = (reached = 0, pending = 0, additionalData = 0) => {
      setseries([reached, pending]);
      setSeries2(additionalData);
    };

    const updateOptions = (labels1, labels2) => {
      setOptions((prevState) => ({
        ...prevState,
        labels: labels1,
      }));
      setOptions2((prevState) => ({
        ...prevState,
        labels: labels2,
      }));
    };

    if (activityCall?.data) {
      const { callPendingCount = 0, reached_count = 0, callAnswerCount = 0, pending_count = 0 } = activityCall?.data;
      updateSeries(reached_count, pending_count, [callAnswerCount, callPendingCount]);
      updateOptions(['Reached', 'Pending'], ['Answered', 'Not Answered']);
    } else {
      updateSeries();
      updateOptions(['Reached', 'Pending'], ['Answered', 'Not Answered']);
    }
  }, [activityCall]);

  useEffect(() => {
    const primaryMain = theme.palette.primary.main;
    const greenColor = '#808080';

    setOptions((prevState) => ({
      ...prevState,
      colors: [greenColor, primaryMain],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary],
          },
        },
      },
      grid: { borderColor: line },
      stroke: { colors: [backColor] },
    }));

    setOptions2((prevState) => ({
      ...prevState,
      colors: [primary, '#808080'],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary],
          },
        },
      },
      grid: { borderColor: line },
      stroke: { colors: [backColor] },
    }));
  }, [primary, line, backColor, theme]);

  const pieChartOptions = {
    chart: {
      type: 'donut',
      height: 320
    },
    labels: ['Reached', 'Pending'],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    }

  };

  const [options, setOptions] = useState(pieChartOptions);
  const [options2, setOptions2] = useState({
    chart: { type: 'donut', height: 420 },
    labels: ['Answered', 'Not Answered'],
    legend: { show: false },
    dataLabels: { enabled: false },
  });


  useEffect(() => {
    const currentQuestion = questionsData[currentQuestionIndex];
    setBarChartOptions((prev) => ({
      ...prev,
      xaxis: {
        categories: currentQuestion?.options.map((item) => item.option),
      },
      title: {
        text: currentQuestion?.question,
        align: 'center',
      },
    }));
    setBarChartSeries([
      {
        name: "Responses",
        data: currentQuestion?.options.map((item) => item.count),
      },
    ]);
  }, [currentQuestionIndex, questionsData]);


  return (
    <MainCard>
      <Form form={form} className='mt-4' layout='vertical'>

        <div>
          <h3 style={{ marginBottom: "20px" }}>Edit Call Activity</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ justifyContent: "left" }}>
              <AddLeadsDrawer
                leadsDataSource={leadsDataSource}
                datacampaign={location?.state?.data}
                handleNavigate={handleNavigate}
                status={STATUS}
                type={'Call'}
              >
                <Button
                  style={{
                    backgroundColor: "#0055A2",
                    color: "#fff",
                    fontFamily: "poppins",
                    fontSize: "13px",
                  }}
                  size="large"
                >
                  <IoMdAddCircle color="#fff" size={24} />
                  Add Leads
                </Button>
              </AddLeadsDrawer>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <div className="count-box">
                <span>Total: </span>{location?.state?.data?.leads?.length}
              </div>
              <div className="count-box">
                <span>New: </span>
                {activityCall?.data?.data?.[0]?.leads && Array.isArray(activityCall?.data?.data?.[0]?.leads) ?
                  activityCall.data?.data[0].leads.length : 0}
              </div>

            </div>
          </div>

          <div style={{ marginTop: 20, display: "flex", gap: 20 }}>
            <div>
              <label style={{ display: "block", marginBottom: 5, fontSize: 13 }}>
                Select Agent
              </label>
              <Select
                placeholder="Select Agent"
                style={{ width: 300 }}
                value={agent}
                disabled={STATUS == 1}
                onChange={setAgent}
                mode="multiple"
              >
                {agentOptions.map((agent) => (
                  <Option key={agent.value} value={agent.value}>
                    {agent.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div>
              <label style={{ display: "block", marginBottom: 5, fontSize: 13 }}>
                Select Script
              </label>
              <Select
                placeholder="Select Template"
                style={{ width: 300, }}
                value={selectedTemplate}
                disabled={STATUS == 1}
                onChange={handleTemplateChange}
              >
                {templateOptions?.map((template) => (
                  <Option key={template?.id} value={template?.id}>
                    {template?.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div>
              <label style={{ display: "block", marginBottom: 5, fontSize: 13 }}>
                Select Provider
              </label>
              <Select
                placeholder="Select Provider"
                style={{ width: 300, }}
                value={selectedSettingId}
                disabled={STATUS == 1}
                onChange={(v) => setSelectedSettingId(v)}
              >
                {callActivitySetting?.map((provider) => (
                  <Option key={provider.id} value={provider.id}>
                    {provider.full_name} ( {provider?.username} )
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <Form.Item
              name="script"
              label="Script Name"
              rules={[
                { required: true, message: "Please input the script name!" },
              ]}

            >
              <Input placeholder="Enter script name" value={scriptName} onChange={(e) => setScriptName(e.target.value)} disabled={STATUS == 1} />
            </Form.Item>
            {STATUS == 1 ? <p dangerouslySetInnerHTML={{ __html: editorValue }} ></p> : <Form.Item
              label="Call Script"
              name="description"
              rules={[
                {
                  required: true,
                  validator: () => {
                    return editorValue == '<p> </p>' || editorValue === '<p><br></p>' || editorValue == ''
                      ? Promise.reject("Call script is required") : Promise.resolve();
                  },
                },
              ]}
            >
              <TextRichEditor value={editorValue} onChange={setEditorValue} placeholder={"Call Script"} />
            </Form.Item>}
            {/* <AddCallScriptPage EditActivityTemplate={EditActivityTemplate} /> */}
          </div>

          <div>
            <h6 style={{ fontWeight: "900", color: "black", marginTop: 30 }}>
              Leads Column Names:
            </h6>
            {EditActivityLeads()?.map((e) => (
              <div key={e.key} style={{ marginBottom: 4 }}>
                {e?.title}
              </div>
            ))}
          </div>
          {/* -----------------------------------------------CHARTS----------------------------- */}
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: "35px", fontWeight: "bold", fontSize: "15px" }}>
            <MainCard style={{ width: "50%" }}>
              <Typography variant="body1" component="span" style={{ marginLeft: '8px', alignItems: 'center' }}>
                Leads
              </Typography>
              <Box flex="1 1 auto" textAlign="center">
                {loading || !options || !series ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={downSM ? 130 : 200}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="pie"
                      height={downSM ? 130 : 200}
                    />
                    <MainCard content={false} sx={{ bgcolor: 'background.default', border: 'none' }}>
                      <Box mt="16px" textAlign="center">
                        <Box
                          display="flex"
                          justifyContent="center"
                          gap="12px"
                          fontSize="14px"
                          color="#555"
                        >
                          {options.colors && options.colors.length > 0 && series && series.length > 0 && (
                            <>
                              <Box>
                                <Typography variant="body1" component="span" style={{ color: options.colors[0], fontWeight: 'bold' }}>
                                  ●
                                </Typography>
                                <Typography variant="body1" component="span" style={{ marginLeft: '8px' }}>
                                  Total Reached
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#333' }}>
                                  {series[0]}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1" component="span" style={{ color: options.colors[1], fontWeight: 'bold' }}>
                                  ●
                                </Typography>
                                <Typography variant="body1" component="span" style={{ marginLeft: '8px' }}>
                                  Total Pending
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#333' }}>
                                  {series[1]}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    </MainCard>
                  </>
                )}
              </Box>
            </MainCard>

            <MainCard style={{ width: "50%" }}>
              <Typography variant="body1" component="span" style={{ marginLeft: '8px', alignItems: 'center', fontWeight: "bold", fontSize: "15px" }}>
                Leads Answers
              </Typography>
              <Box flex="1 1 auto" textAlign="center">
                {loading || !options2 || !series2 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={downSM ? 130 : 200}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <ReactApexChart
                      options={options2}
                      series={series2}
                      type="pie"
                      height={downSM ? 130 : 200}
                    />
                    <MainCard content={false} sx={{ bgcolor: 'background.default', border: 'none' }}>

                      <Box mt="16px" textAlign="center">
                        <Box
                          display="flex"
                          justifyContent="center"
                          gap="12px"
                          fontSize="14px"
                          color="#555"
                        >
                          {options2.colors && options2.colors.length > 0 && series2 && series2.length > 0 && (
                            <>
                              <Box>
                                <Typography variant="body1" component="span" style={{ color: options2.colors[0], fontWeight: 'bold' }}>
                                  ●
                                </Typography>
                                <Typography variant="body1" component="span" style={{ marginLeft: '8px' }}>
                                  Answered
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#333' }}>
                                  {series2[0]}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1" component="span" style={{ color: options2.colors[1], fontWeight: 'bold' }}>
                                  ●
                                </Typography>
                                <Typography variant="body1" component="span" style={{ marginLeft: '8px' }}>
                                  Not Answered
                                </Typography>
                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold', color: '#333' }}>
                                  {series2[1]}
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    </MainCard>
                  </>
                )}
              </Box>
            </MainCard>
          </div>


          <MainCard>
            <div style={{ marginTop: 32 }}>
              {Array.isArray(questionsData) && questionsData?.length !== 0 && questionsData?.map((question, index) => {
                const barHeight = 20;
                const chartHeight = Math.max(140, 60);

                const barChartOptions = {
                  chart: {
                    type: 'bar',
                    height: chartHeight,
                    toolbar: { show: false },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                      barHeight: '30%',
                    },
                  },
                  xaxis: {
                    categories: question?.options.map((option) => option?.option),
                    labels: { show: false },
                  },
                  title: {
                    text: question?.question,
                    align: 'center',
                    style: {
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#333',
                      fontFamily: "poppins",
                    },
                  },
                  colors: [theme.palette.primary.main],
                  grid: {
                    show: false,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    theme: 'dark',
                    y: {
                      formatter: function (val) {
                        return Number.isInteger(val) ? val : val.toFixed(1); // Ensures integers are displayed as whole numbers
                      },
                    }
                  },
                };

                const barChartSeries = [
                  {
                    name: 'Count ',
                    data: question?.options?.map((option) => option?.count),
                  },
                ];

                return (
                  <div
                    id="chart"
                    key={index}
                    style={{
                      marginBottom: 24,
                      // border: '1px solid #e0e0e0',
                      borderRadius: 12,
                      width: '100%',
                      maxWidth: '100%',
                      margin: '0 auto',
                      overflow: 'hidden',
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: chartHeight,
                        }}
                      >
                        <Spin />
                      </div>
                    ) : (
                      <ReactApexChart
                        options={barChartOptions}
                        series={barChartSeries}
                        type="bar"
                        height={chartHeight}
                      />
                    )}
                  </div>
                );
              })}


            </div>
          </MainCard>


          {/* -----------------------------------------------CHARTS----------------------------- */}


          {STATUS == 1 || STATUS == 2 ? <div>
            {/* <h2 className="my-4">Questions:</h2>

            {activityCall?.data?.data?.[0] && activityCall?.data?.data[0]?.questions.map(v => <div key={v.id} className="d-flex gap-4">
              <p>{v?.question}</p> {v?.type != 'Text' ? <p>{JSON.parse(v?.values).map(v => <span> {v} </span>)}</p> : ""}
            </div>)} */}
          </div> :
            <EditableFormFields
              additionalFields={additionalFields}
              setAdditionalFields={setAdditionalFields}
            />}

          {STATUS != 1 && (
            <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>
              <Button
                style={{
                  backgroundColor: "#0055A2",
                  color: "#fff",
                  fontFamily: "poppins",
                  fontSize: "13px",
                  minWidth: "150px",
                }}
                onClick={() => HandleSave(1)}
                size="large"
              >
                {loadingBtn ? "Loading..." : "Save"}

              </Button>
              <Button
                style={{
                  backgroundColor: "#0055A2",
                  color: "#fff",
                  fontFamily: "poppins",
                  fontSize: "13px",
                  minWidth: "150px",
                }}
                onClick={() => HandleSave(2)}
                size="large"
              >
                {loadingBtn ? "Loading..." : "Save as Draft"}

              </Button>
            </div>
          )}
          <div style={{ overflowX: "auto" }}>
            <Table
              className="border rounded"
              style={{ marginTop: 25 }}
              dataSource={getActivityHistory()}
              columns={EditActivityLeadsLogs()}
              rowKey="id"
              expandable={{
                expandedRowRender
              }}
            />
          </div>
        </div>
      </Form>
    </MainCard>
  );
};

const mapStateToProps = (state) => ({
  allCallscript: state?.AllCallScriptReducer?.allCallscript,
  allTeamMembers: state?.GetAllUsers?.getAllUsers,
  activityDetail: state?.ActivityDetailRedc?.activityDetails,
  callActivitySetting: state?.AllCallSettingReducer?.allCallsetting?.data ?? [],
  activityCall: state?.ActivityCallRedc?.activityCall

});

export default connect(mapStateToProps)(CallActivityEdit);