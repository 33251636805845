import { LEADS_DETAILS } from "../../constant/ActionType";

export default function AllLeadDetailsDataRed(
  state = {
    alldataLeadDeatil: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case LEADS_DETAILS:
      return {
        ...state,
        alldataLeadDeatil: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
