import { ALL_LEADS } from "../../constant/ActionType";

export default function AllLeadsReducer(
  state = {
    allLeads: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_LEADS:
      return {
        ...state,
        allLeads: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
