import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project-imports
import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';

// ==============================|| NAVIGATION - ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  const IsDrawer = item.is_drawer ?? null;
  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = { component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon variant="Bulk" size={drawerOpen ? 20 : 22} /> : false;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = 'sicpa.main';
  const iconSelectedColor = '#ffff';

  return (
    item.is_drawer ? (
      <IsDrawer>
        <ListItemButton
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            color: isSelected ? "#ffff" : "#0055A2",
            w: '100%',
            zIndex: 1201,
            pl: drawerOpen ? `${level * 20}px` : 1.5,
            mx: 2,
            py: !drawerOpen && level === 1 ? 1.25 : 1,
            '&:hover': {
              bgcolor: '#0055A2 !important',
              color: '#ffff !important'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: '#0055A2 !important'
              },
              bgcolor: '#0055A2 !important'
            },
            ...(drawerOpen && {
              '&:hover': {
                bgcolor: '#0055A2 !important',
                color: '#ffff !important'
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: '#0055A2 !important',
                  color: '#ffff !important'
                },
                bgcolor: '#0055A2 !important',
                color: '#ffff !important'
              }
            })
          }}
          {...(downLG && {
            onClick: () => dispatch(openDrawer(false))
          })}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 38
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          <ListItemText
            primary={
              <>

                <Typography
                  variant="h6"

                >
                  Add Campaign
                </Typography>

              </>
            }
          />

        </ListItemButton>
      </IsDrawer>
    ) : (
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          color: isSelected ? "#ffff" : "#0055A2",
          zIndex: 1201,
          pl: drawerOpen ? `${level * 20}px` : 1.5,
          mx: 2,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          '&:hover': {
            bgcolor: '#0055A2 !important',
            color: '#ffff !important'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: '#0055A2 !important'
            },
            bgcolor: '#0055A2 !important'
          },
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: '#0055A2 !important',
              color: '#ffff !important'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: '#0055A2 !important',
                color: '#ffff !important'
              },
              bgcolor: '#0055A2 !important',
              color: '#ffff !important'
            }
          })
        }}
        {...(downLG && {
          onClick: () => dispatch(openDrawer(false))
        })}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 38,
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}

        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  fontWeight: isSelected ? 500 : 400,
                }}
              >
                {item.title}
              </Typography>
            }
          />
        )}
      </ListItemButton>
    )
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  target: PropTypes.object,
  url: PropTypes.string,
  id: PropTypes.object,
  disabled: PropTypes.bool,
  chip: PropTypes.object,
  variant: PropTypes.string,
  title: PropTypes.string,
  avatar: PropTypes.object,
  label: PropTypes.string
};

export default NavItem;
