import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import {
  can_add_users,
  can_view_tags,
  can_view_users,
} from "helper/permissionsCheck";

// Define icons
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

// Define Leads menu based on permissions
const tagsMenu = can_view_tags
  ? {
      id: "tags-group",
      title: "Tags",
      icon: icons.allLeads,
      type: "group",
      children: [
        {
          id: "all-tags",
          title: "All Tags",
          type: "item",
          url: "/tags/all-tags",
          icon: icons.allLeads,
          breadcrumbs: true,
        },
      ].filter(Boolean),
    }
  : null;

export default tagsMenu;
