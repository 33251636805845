import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, InputNumber } from "antd";
import { Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import {
    FetchCreateCallSetting,
    FetchUpdateCallSetting,
} from "store/action/index";
import HandlePasswordDecrypte from "helper/password-decrypte";

const { Option } = Select;

const AddCallSetting = () => {
    const [form] = Form.useForm();
    const [ConditionDisabledCHeck, setDisabledCOndotionCHeck] = useState(false)
    const [loadinBtn, setLoadingBtn] = useState(false)
    const location = useLocation();

    const isEditing = location.state ? true : false;
    const initialData = location.state ? location.state : null;

    useEffect(() => {

        if (isEditing && initialData && form.getFieldValue('is_default') == true) {
            setDisabledCOndotionCHeck(true)
        } else {
            setDisabledCOndotionCHeck(false)
        }
    }, [isEditing, initialData, form])

    useEffect(() => {
        if (isEditing && initialData) {
            form.setFieldsValue({
                server: initialData.server,
                port: initialData.port,
                path: initialData.path,
                full_name: initialData.full_name,
                domain: initialData.domain,
                username: initialData.username,
                password: HandlePasswordDecrypte(initialData.password),
                active: initialData.active == 1 ? true : false,
                is_default: initialData.is_default == 1 ? true : false,
            });
        }
    }, [form, initialData, isEditing]);

    const onFinish = (values) => {
        setLoadingBtn(true)
        if (isEditing) {
            message.success("Call setting updated successfully!");
            const payload = {
                ...(values && { id: initialData?.id, _method: "PUT" }),
                server: values.server,
                port: values.port,
                path: values.path,
                full_name: values.full_name,
                domain: values.domain,
                username: values.username,
                password: values.password,
                active: values.active,
                is_default: values.is_default,
            };
            dispatch(FetchUpdateCallSetting({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
        } else {
            message.success("Call setting created successfully!");
            dispatch(FetchCreateCallSetting({ fieldData: values, setLoadingBtn: setLoadingBtn }));
        }
    };

    return (
        <MainCard>
            <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{
                is_default: false,
                active: true,
            }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="server"
                            label="Server"
                            tooltip="Enter the server"
                            rules={[{ required: true, message: "Please enter the server" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="port"
                            label="Port"
                            tooltip="Enter the port"
                            rules={[{ required: true, message: "Please enter the port" }]}
                        >
                            <InputNumber style={{ width: "100%" }} min={1} max={65535} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="path"
                            label="Path"
                            tooltip="Enter the path"
                            rules={[{ required: true, message: "Please enter the path" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item
                            name="full_name"
                            label="Full Name"
                            tooltip="Enter full name"
                            rules={[{ required: true, message: "Please enter full name" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="domain"
                            label="Domain"
                            tooltip="Enter Domain"
                            rules={[{ required: true, message: "Please enter Domain" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="username"
                            label="Username"
                            tooltip="Enter Username"
                            rules={[{ required: true, message: "Please enter Username" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="password"
                            label="Password"
                            tooltip="Enter the password"
                            rules={[{ required: true, message: "Please enter the password" }]}
                        >
                            <Input.Password min={6} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>


                        <Form.Item
                            name="active"
                            label="Status"
                            tooltip="Select the active status"
                            rules={[{ required: true, message: "Please select the status" }]}
                        >
                            <Select>
                                <Option value={true}>Active</Option>
                                <Option value={false}>Deactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="is_default"
                            label="Default"
                            tooltip="Select the Default"
                            rules={[{ required: true, message: "Please select default" }]}
                        >
                            <Select disabled={ConditionDisabledCHeck}
                            >
                                <Option value={true}>True</Option>
                                <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loadinBtn}>
                        {isEditing ? "Update" : "Submit"}
                    </Button>
                </Form.Item>
            </Form>
        </MainCard>)
}

export default AddCallSetting