
// type
import { ShopRemove } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { MdAssignment } from "react-icons/md";
import { can_add_roles, can_edit_roles, can_view_roles } from "../helper/permissionsCheck";
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  assignPermission: MdOutlineSecurity,
  color: MdHome,
  shadow: MdHome,
  role: MdAssignmentTurnedIn,
  Allrole: MdAssignment,
};

const roles = (can_edit_roles||can_view_roles||can_add_roles)? {
  id: 'aaall-permissions',
  title: 'Roles',
  icon: icons.Allrole,
  type: 'group',
  children: [
    {
      id: 'aaall-assign-permissions',
      title: 'All Roles',
      type: 'item',
      url: '/roles/all-roles',
      icon: icons.Allrole,
      breadcrumbs: true
    }

  ].filter(Boolean)
}:null

export default roles;
