import { ROLE_PERMISSIONS } from "../constant/ActionType";

export default function GetRolePermissions(
  state = {
    getRolePermissions: [],
  },
  action
) {
  switch (action.type) {
    case ROLE_PERMISSIONS:
      return {
        ...state,
        getRolePermissions: action.data,
      };

    default:
  }

  return state;
}
