import React from "react";
import MainCard from "components/MainCard";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Button,Tooltip } from "@mui/material";
import { Modal, Form, Input, Checkbox } from "antd";
import { toast } from "react-toastify";
import { useState } from "react";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import { dispatch } from "store/index";
import { AddEmailTemplate, EditEmailTemplate } from "store/action/index";
import { FaRegLightbulb } from "react-icons/fa";

const CreateEditEmailTemplate = ({ children, setLoading, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState(data?.body || "");
  const [isRichEditor, setIsRichEditor] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false)

  const showModal = () => {
    setIsModalOpen(true);
    if (data) {
      form.setFieldsValue({
        templateName: data?.name,
        subject: data?.subject,
        body: data?.body,
        footer: data?.footer,
      });
      setEditorValue(data?.body);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...(data && { id: data?.id, _method: "PUT" }),
          name: values.templateName,
          subject: values.subject,
          body: isRichEditor ? editorValue : values.body,
          footer: values.footer,
        };
        setLoadingBtn(true)
        if (data) {
          dispatch(EditEmailTemplate({ payload, setLoading, setLoadingBtn: setLoadingBtn }));
        } else {
          dispatch(AddEmailTemplate({ payload, setLoading, setLoadingBtn: setLoadingBtn }));
        }

        form.resetFields();
        setEditorValue("");
        handleCancel();
      })
      .catch(() => {
        toast.error("Please fill out all required fields", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  };

  const toggleEditor = (checked) => {
    setIsRichEditor(checked);
    const currentValue = isRichEditor ? editorValue : form.getFieldValue("body");
    if (checked) {
      setEditorValue(currentValue);
    } else {
      form.setFieldsValue({ body: currentValue });
    }
  };

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        title={data ? "Update Email Template" : "Add Email Template"}
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            templateName: data?.name || "",
            subject: data?.subject || "",
            body: data?.body || "",
            footer: data?.footer || "",
          }}
        >
          <Form.Item
            label="Template Name"
            name="templateName"
            rules={[{ required: true, message: "Template Name is required" }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Subject is required" }]}
          >
            <Input placeholder="Enter subject" />
          </Form.Item>

          <Form.Item style={{ marginBottom: "5px", marginLeft: "3px" }}>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <div>
      <Checkbox
        checked={isRichEditor}
        onChange={(e) => toggleEditor(e.target.checked)}
      >
        Use Rich Text Editor
      </Checkbox>
    </div>
    <div style={{ marginLeft: "25px" }}>
    <Tooltip title="To include the lead's name, use curly braces like {name}. in the Body field">
        <span>
          <FaRegLightbulb size={18} color="#ffa726" />
        </span>
      </Tooltip>
    </div>
  </div>
</Form.Item>




          <Form.Item
            label="Body"
            name="body"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!isRichEditor) {
                    const htmlValue = form.getFieldValue("body");
                    return !htmlValue
                      ? Promise.reject("Body is required")
                      : Promise.resolve();
                  } else {
                    return editorValue == "<p> </p>" ||
                      editorValue === "<p><br></p>" ||
                      editorValue == ""
                      ? Promise.reject("Body is required")
                      : Promise.resolve();
                  }
                },
              },
            ]}
          >
            {isRichEditor ? (
              <TextRichEditor
                value={editorValue}
                onChange={setEditorValue}
                placeholder={"Enter email body"}
              />
            ) : (
              <Input.TextArea rows={4} placeholder="<>...</>" />
            )}
          </Form.Item>

          <Form.Item
            label="Footer"
            name="footer"
            rules={[{ required: false, message: "" }]}
          >
            <Input.TextArea rows={2} placeholder="Enter email footer body" />
          </Form.Item>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disableElevation
                className="px-4"
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {loadingBtn ? 'Processing...' : (data ? "Update" : "Add")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(CreateEditEmailTemplate);