import { CALL_MANAGER_DATA } from "../constant/ActionType";

export default function AllCallsmanagersDataReducers(
  state = {
    allCallManagerdata: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case CALL_MANAGER_DATA:
      return {
        ...state,
        allCallManagerdata: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
