import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllSMSTemplates } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AllSmsTemplate } from "components/columns/columns";
import CreateEditSmsTemplate from "./Create&EditSmsTemplate";
import {
    can_view_sms_templates,
    can_add_sms_templates,
    can_edit_sms_templates,
} from "helper/permissionsCheck";
const SmsTemplate = ({ allsmsTemplates }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(FetchAllSMSTemplates({ setLoading: setLoading }));
    }, [])

    useEffect(() => {
    }, [allsmsTemplates])

    const columns = AllSmsTemplate();


    {
        can_edit_sms_templates ? columns.push({
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                const editButton = true ? (
                    <CreateEditSmsTemplate setLoading={setLoading} data={record}>
                        <IconButton className="mx-2" type="primary" variant="contained">
                            <EditOutlined />
                        </IconButton>
                    </CreateEditSmsTemplate>
                ) : null;

                return <span>{editButton}</span>;
            },
        }) : <></>
    };



 


    return (
        <>
            {can_add_sms_templates ? <Grid container alignItems="center" justifyContent="flex-end">
                <CreateEditSmsTemplate setLoading={setLoading}>
                    <Button disableElevation className="px-2 my-2" size="large" type="submit">
                        <span className="mx-2">
                            <IoMdAddCircle color="#FF264C" size={26} />
                        </span>{" "}
                        Add Sms Template
                    </Button>
                </CreateEditSmsTemplate>
            </Grid> : <></>}
            {can_view_sms_templates ? <MainCard>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        dataSource={Array.isArray(allsmsTemplates) ? allsmsTemplates : []}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
                {/* <div style={{ textAlign: "center", marginTop: 16 }}>
                <Pagination
                    current={allcompaigns?.meta?.current_page || 1}
                    total={allcompaigns?.meta?.total || 0}
                    pageSize={allcompaigns?.meta?.per_page || 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div> */}
            </MainCard> : <></>}
        </>
    )
}




const mapStateToProps = (state) => {
    return {
        allsmsTemplates: state.AllSmsTemplatesReducer.allsmsTemplates,

    };
};

export default connect(mapStateToProps)(SmsTemplate);