import { ALL_ACTIVITES } from "../../constant/ActionType";

export default function AllActivities(
  state = {
    allActivities: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_ACTIVITES:
      return {
        ...state,
        allActivities: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
