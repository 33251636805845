import { LEAD_HISTORY } from "../../constant/ActionType";

export default function LeadHistoryAllRedcucer(
  state = {
    allLeadhistorydata: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case LEAD_HISTORY:
      return {
        ...state,
        allLeadhistorydata: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
