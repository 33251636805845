import { GET_ALL_LOGS} from "../../constant/ActionType";

export default function GetAllLogsReducer(
    state = {
        alllogsdataaa: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case GET_ALL_LOGS:
            return {
                ...state,
                alllogsdataaa: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
