import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  FetchAllEmailTemplates,
  FetchDeleteEmailTemplate,
} from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ThemeInput from "components/inputs/ThemeInput";
import { IoMdAddCircle } from "react-icons/io";
import CreateEditEmailTemplate from "./CreateEditEmailTemplates";
import {
  can_add_email_templates,
  can_view_email_templates,
  can_edit_email_templates,
  can_delete_email_templates,
} from "helper/permissionsCheck";
// ==============================|| EMAIL TEMPLATES - MANAGEMENT ||============================== //

const AllEmailTemplates = ({ emailTemplates }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(FetchAllEmailTemplates({ setLoading: setLoading }));
  }, []);

  const showConfirm = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => handleDelete(record), 
    });
  };
  const columns = [

    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
  ];

  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <>
          
          {can_edit_email_templates ? <CreateEditEmailTemplate setLoading={setLoading} data={record}>
            <IconButton className="mx-2" type="primary" variant="contained">
              <EditOutlined />
            </IconButton>
          </CreateEditEmailTemplate> : <></>}

          {can_delete_email_templates ? <IconButton
            className="mx-2"
            type="primary"
            variant="contained"
            onClick={() => showConfirm(record)}
          >
            <DeleteOutlined />
          </IconButton> : <></>}
        </>
      );
    },
  });

  const handleDelete = (record) => {
    setLoading(true);
    const payload = {
      id: record.id,
      _method: "DELETE",
    };
    dispatch(FetchDeleteEmailTemplate({ payload, setLoading }));
  };

  return (
    <>
      {can_add_email_templates ? <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Email Templates
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <CreateEditEmailTemplate setLoading={setLoading}>
            <Button
              disableElevation
              className="px-2"
              size="large"
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#FF264C" size={26} />
              </span>{" "}
              Add Template
            </Button>
          </CreateEditEmailTemplate>
        </Grid>
      </div> : <></>}

      {can_view_email_templates ? <MainCard>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={emailTemplates ?? []}
            columns={columns}
          />
        </Spin>
      </MainCard> : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailTemplates: state.EmailTemplatesReducer.emailTemplates,
  };
};

export default connect(mapStateToProps)(AllEmailTemplates);
