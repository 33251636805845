import { ALL_CALL_SCRIPT } from "../../constant/ActionType";

export default function AllCallScriptReducer(
  state = {
    allCallscript: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_CALL_SCRIPT:
      return {
        ...state,
        allCallscript: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
