import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import MainCard from "components/MainCard";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { FetchCreateNewWhatsappSetting } from "store/action/index";

const WhatsappSetting = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loadinBtn, setLoadingBtn] = useState(false)

  const isEditing = location.state ? true : false;
  const initialData = location.state ? location.state : null;

  useEffect(() => {
    if (isEditing && initialData) {
      form.setFieldsValue({
        apikey: initialData.apikey,
        apiurl: initialData.apiurl,
        sender_name: initialData.sender_name,
        cascade_id: initialData.cascade_id,
      });
    }
  }, [form, initialData, isEditing]);

  const onFinish = (values) => {
    setLoadingBtn(true)
    if (isEditing) {
      message.success("WhatsApp setting updated successfully!");
      navigate("/settings/all-whatsapp-setting");
    } else {
      dispatch(FetchCreateNewWhatsappSetting({ fieldData: values, setLoadingBtn: setLoadingBtn }));
      message.success("WhatsApp setting created successfully!");
      navigate("/settings/all-whatsapp-setting");
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <MainCard>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="apikey"
              label="API Key"
              rules={[
                { required: true, message: "Please enter the API Key" },
                { max: 255, message: "API Key cannot exceed 255 characters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="apiurl"
              label="API URL"
              rules={[
                { required: true, message: "Please enter the API URL" },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="sender_name"
              label="Sender Name"
              rules={[
                { required: true, message: "Please enter the Sender Name" },
                {
                  max: 255,
                  message: "Sender Name cannot exceed 255 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="cascade_id"
              label="Cascade ID"
              rules={[
                { required: true, message: "Please enter the Cascade ID" },
                {
                  max: 255,
                  message: "Cascade ID cannot exceed 255 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadinBtn}>
            {isEditing ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </MainCard>
  );
};

export default WhatsappSetting;
