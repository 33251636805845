import { GET_FRESH_CAMPAIGN_LEADS } from "../../constant/ActionType";

export default function AllRefreshCampaignLeadsReducer(
  state = {
    allcompaignsleadsRefreshh: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case GET_FRESH_CAMPAIGN_LEADS:
      return {
        ...state,
        allcompaignsleadsRefreshh: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}

