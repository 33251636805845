import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Card, Button, Space, Row, Col, Modal, Spin } from 'antd';
import { useSelector } from "react-redux";
import {
  Phone,
  Globe,
  Plus,
  X
} from 'lucide-react';
import { TbVariablePlus } from "react-icons/tb";
import { Button as ButtonMui } from "@mui/material";
import { dispatch } from "store/index";
import { AddWhatsappTemplate, FetchWhatsappChannel, FetchCreateWhatsAppSetting } from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;

const CreateWhatsappTemplate = ({ EditActivityTemplate, isDisabled }) => {
  const [form] = Form.useForm();
  const [buttons, setButtons] = useState([]);
  const [preview, setPreview] = useState({
    headerText: '',
    text: '',
    footerText: '',
    buttons: []
  });
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [variableCount, setVariableCount] = useState(0);
  const [variableValues, setVariableValues] = useState([]);
  const [templateValues, setTemplateValues] = useState(null);
  const [hasFilledVariables, setHasFilledVariables] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState('')
  const navigate = useNavigate()
  const selector = useSelector((state) => state);
  const AllchannelData = selector?.AllWhatsappChannnel?.allwhatsappchannel ?? [];
  const [loadingBtn, setLoadingBtn] = useState(false)
  const activeChannels = AllchannelData?.filter(channel => channel.active == 1);
  const whatsappSettingData = selector?.AllWhatsappSettingReducer?.allWhatsappsetting?.data ?? [];

  const buttonTypes = [
    { key: 'URL', icon: <Globe style={{ width: '14px', marginRight: '5px' }} />, label: 'Website' },
    { key: 'PHONE', icon: <Phone style={{ width: '14px', marginRight: '5px' }} />, label: 'Call Us' },
  ];

  useEffect(() => {
    dispatch(FetchWhatsappChannel())
  }, []);
  useEffect(() => {
    dispatch(FetchCreateWhatsAppSetting({ setLoading: setIsLoading }));
  }, []);

  useEffect(() => {
    if (selectedChannel) {
      form.setFieldsValue({
        channelType: selectedChannel.type,
        subjectId: selectedChannel.subjectId,
        subject: selectedChannel.subject
      });
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (EditActivityTemplate) {
      form.setFieldsValue({
        name: EditActivityTemplate.name || '',
        category: 'MARKETING',
        channelType: EditActivityTemplate.channelType || 'WHATSAPP',
        text: EditActivityTemplate.body || '',
        footerText: EditActivityTemplate.footer || '',
        headerText: EditActivityTemplate.header?.content || '',
        subject: EditActivityTemplate.subject || '',
        subjectId: EditActivityTemplate.subjectId || ''
      });

      if (EditActivityTemplate.content?.keyboard?.rows?.[0]?.buttons) {
        const existingButtons = EditActivityTemplate.content.keyboard.rows[0].buttons.map((button, index) => ({
          id: index,
          type: button.buttonType,
          text: button.text,
          value: button.buttonType === 'URL' ? button.url : button.phone
        }));
        setButtons(existingButtons);
      }
      updatePreview(form.getFieldsValue());
    }
  }, [EditActivityTemplate, form]);

  useEffect(() => {
    updatePreview(form.getFieldsValue());
  }, [buttons])

  const handleChannelSelect = (channelId) => {
    const selectedChannel = activeChannels?.find(channel => channel.id === channelId);
    setSelectedChannel(selectedChannel);
  };
  const handleProviderSelect = (pro_id) => {
    setSelectedProvider(pro_id)
  }

  const handleNavigate = () => {
    navigate('/templates/whatsapp-templates')
  }
  const countVariables = (text) => {
    const matches = text.match(/\{\{1\}\}/g);
    return matches ? matches.length : 0;
  };

  const updatePreview = (values) => {
    setPreview((prev) => {
      return {
        ...prev,
        ...(values.headerText && { headerText: values.headerText }),
        ...(values.text && { text: values.text }),
        ...(values.footerText && { footerText: values.footerText }),
        buttons: buttons
      }
    });
  };

  const addButton = (type) => {
    const newButton = {
      id: buttons.length,
      type,
      text: '',
      value: ''
    };
    setButtons([...buttons, newButton]);
  };

  const removeButton = (buttonId) => {
    setButtons(buttons.filter(button => button.id !== buttonId));
    updatePreview(form.getFieldsValue());
  };

  const updateButtonField = (buttonId, field, value) => {
    const updatedButtons = buttons.map(button =>
      button.id === buttonId ? { ...button, [field]: value } : button
    );
    setButtons(updatedButtons);
    updatePreview(form.getFieldsValue());
  };

  const handleVariableModalOk = () => {
    if (variableValues.every(value => value)) {
      setHasFilledVariables(true);
      const matcherFormat = transformToMatcherFormat(templateValues, variableValues);
      setTemplateValues(matcherFormat);
      setIsVariableModalOpen(false);
      // console.log(matcherFormat)
      setIsLoading(true)
      dispatch(AddWhatsappTemplate({
        matcherFormat: { ...matcherFormat, provider_id: selectedProvider },
        handleNavigate: handleNavigate
      }));
    } else {
      alert("Please fill in all variable values");
    }
  };

  const handleVariableModalCancel = () => {
    setIsVariableModalOpen(false);
  };

  const WhatsAppPreview = () => (
    <div className="bg-gray-100 p-4 rounded-lg">
      {preview.headerText && (
        <div className="font-bold text-gray-800 mb-2">
          Character Count: {(preview?.headerText?.length || 0) +
            (preview?.text?.length || 0) +
            (preview?.footerText?.length || 0)}
        </div>)}
      <div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
        {preview.headerText && (
          <div className="font-bold text-gray-800">{preview.headerText}</div>
        )}

        {preview.text && (
          <div className="text-gray-700 whitespace-pre-wrap">{preview.text}</div>
        )}

        {preview.footerText && (
          <div className="text-sm text-gray-500">{preview.footerText}</div>
        )}

        {buttons.length > 0 && (
          <div className="space-y-2 d-flex flex-column pt-2 border-t">
            {buttons.map((button) => (
              <Button
                key={button.id}
                className="w-full bg-blue-600 m-1 rounded"
              >
                {button.text || `New ${button.type} Button`}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const transformToMatcherFormat = (values, textExampleParams = []) => {
    const formattedButtons = buttons.map(button => {
      const baseButton = {
        text: button.text,
        buttonType: button.type
      };

      if (button.type === 'URL') {
        return {
          ...baseButton,
          url: button.value,
          urlTextExample: button.value
        };
      } else {
        return {
          ...baseButton,
          phone: button.value
        };
      }
    });

    return {
      messageMatcher: {
        name: values.name,
        channelType: values.channelType,
        language: "EN",
        content: {
          header: values.headerText ? {
            headerType: 'TEXT',
            text: values.headerText,
            headerExampleTextParam: values.headerExample || ""
          } : undefined,
          text: values.text,
          textExampleParams: textExampleParams,
          keyboard: buttons.length > 0 ? {
            rows: [{
              buttons: formattedButtons
            }]
          } : undefined,
          footer: values.footerText ? {
            text: values.footerText
          } : undefined
        },
        category: "MARKETING",
        type: "OPERATOR",
        subject: values.subject,
      },
      subjectIds: [values.subjectId]

    };
  };

  const onFinish = (values) => {
    const count = countVariables(values.text || '');

    if (count > 0 || !hasFilledVariables) {
      setVariableCount(count);
      setVariableValues(new Array(count).fill(''));
      setTemplateValues(values);
      setIsVariableModalOpen(true);
    } else {
      // console.log(hasFilledVariables ? templateValues : transformToMatcherFormat(values))
      setIsLoading(true)
      setLoadingBtn(true)
      dispatch(AddWhatsappTemplate({
        matcherFormat: hasFilledVariables ? { ...templateValues, provider_id: selectedProvider } : { ...transformToMatcherFormat(values), provider_id: selectedProvider },
        handleNavigate: handleNavigate,
        setLoadingBtn: setLoadingBtn
      }));
    }
  };

  const handleAddVariable = () => {
    const bodyText = form.getFieldValue('text');
    if (!bodyText) {
      alert("Body Text cannot be empty.");
    } else {
      form.setFieldsValue({ text: `${bodyText} {{1}}` });
      updatePreview(form.getFieldsValue());
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[1-9]\d{1,14}$/; // For E.164 format
    if (!phoneRegex.test(phoneNumber)) {

      return <div><p style={{ color: 'red', fontSize: '10px', marginLeft: '4px', marginTop: '5px' }} >Phone Number is not valid</p></div>
    } else {
      return <></>
    }
  };


  return (
    <>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 24]} className="p-4">
          {!isDisabled && (
            <Col xs={24} sm={14}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={updatePreview}
                initialValues={{
                  category: 'MARKETING',
                  channelType: 'WHATSAPP'
                }}
              >
                <Card className="mb-4">
                  <Form.Item
                    name="name"
                    label="Template Name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter template name" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    name="channel"
                    label="Select Channel"
                    rules={[{ required: true, message: 'Please select a channel' }]}
                  >
                    <Select
                      placeholder="Select a channel"
                      onChange={handleChannelSelect}
                      disabled={isDisabled}
                    >
                      {Array.isArray(activeChannels) && activeChannels.length !== 0 && activeChannels?.map((channel) => (
                        <Option key={channel.id} value={channel.id}>
                          {channel.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="Provider"
                    label="Select Provider"
                    rules={[{ required: true, message: 'Please select a Provider' }]}
                  >
                    <Select
                      placeholder="Select a Provider"
                      onChange={handleProviderSelect}
                      disabled={isDisabled}
                    >
                      {Array.isArray(whatsappSettingData) && whatsappSettingData.length !== 0 && whatsappSettingData?.map((channel) => (
                        <Option key={channel.id} value={channel.id}>
                          {channel.sender_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="channelType"
                    label="Channel Type"
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="subject"
                    label="Subject"
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="subjectId"
                    label="Subject ID"
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>
                </Card>

                <Card title="Message Content" className="mb-4">
                  <Form.Item name="headerText" label="Header Text">
                    <Input placeholder="Example: Your company {{1}}" disabled={isDisabled} />
                  </Form.Item>

                  <Form.Item
                    name="text"
                    label="Body Text"
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Example: Hello, {{1}}! Thanks for choosing {{2}}"
                      disabled={isDisabled}
                    />
                  </Form.Item>
                  <ButtonMui
                    onClick={handleAddVariable}
                    title="Add Variable"
                    variant="contained"
                    className="my-2"
                  >
                    <TbVariablePlus />
                  </ButtonMui>

                  <Form.Item name="footerText" label="Footer Text">
                    <Input placeholder="Add a footer note" disabled={isDisabled} />
                  </Form.Item>
                </Card>

                <Card title="Buttons" className="mb-4">
                  <div className="grid grid-cols-12 gap-2 mb-4 d-flex justify-content-center">
                    {buttonTypes.map(({ key, icon, label }) => (
                      <Button
                        key={key}
                        onClick={() => addButton(key)}
                        className="text-left grid-cols-12 flex items-center m-1 gap-4"
                        disabled={isDisabled || buttons.length >= 3}
                        style={{ width: '45%' }}
                      >
                        {icon}
                        <span className="pl-4">{label}</span>
                      </Button>
                    ))}
                  </div>

                  <Space direction="vertical" className="w-full">
                    {buttons.map((button) => (
                      <Card
                        key={button.id}
                        size="small"
                        className="w-full"
                        title={button.type === 'URL' ? "Link" : "Phone"}
                        extra={
                          <Button
                            type="text"
                            onClick={() => removeButton(button.id)}
                            className="p-0"
                            disabled={isDisabled}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        }
                      >
                        <div className="space-y-4">
                          <Input
                            placeholder="Button Text"
                            value={button.text}
                            onChange={(e) => updateButtonField(button.id, 'text', e.target.value)}
                            disabled={isDisabled}
                          />

                          <Input
                            placeholder={button.type === 'URL' ? 'Website URL' : '+921234567890 (must be in this format)'}
                            value={button.value}
                            onChange={(e) => updateButtonField(button.id, 'value', e.target.value)}
                            disabled={isDisabled}
                            className="mt-2"
                            type={button.type === 'URL' ? 'url' : 'text'}
                          />
                          {button.type === 'URL' ? <></> : validatePhoneNumber(button.value)}
                        </div>
                      </Card>
                    ))}
                  </Space>
                </Card>

                <Button type="primary" htmlType="submit" className="w-full" loading={loadingBtn} disabled={isDisabled}>
                  {EditActivityTemplate ? 'Update Template' : 'Save Template'}
                </Button>
              </Form>
            </Col>
          )}

          <Col xs={24} sm={10}>
            <Card title="Template Preview">
              <WhatsAppPreview />
            </Card>
          </Col>
        </Row>
      </Spin>

      <Modal
        title="Enter Variable Values"
        open={isVariableModalOpen}
        onOk={handleVariableModalOk}
        onCancel={handleVariableModalCancel}
      >
        <div className="space-y-4">
          <p>Found {variableCount} variables in your template. Please provide values for each:</p>
          {Array.from({ length: variableCount }).map((_, index) => (
            <Form.Item
              key={index}
              label={`Variable ${index + 1}`}
              className="mb-4"
            >
              <Input
                placeholder={`Enter value for variable ${index + 1}`}
                value={variableValues[index]}
                onChange={(e) => {
                  const newValues = [...variableValues];
                  newValues[index] = e.target.value;
                  setVariableValues(newValues);
                }}
              />
            </Form.Item>
          ))}
        </div>
      </Modal>
    </>

  );
};

export default CreateWhatsappTemplate;