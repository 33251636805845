import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchCreateWhatsAppSetting } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { AllWhatsappSettingCOlumns } from "components/columns/columns";
import {
  can_add_whatsapp_setting,
  can_edit_whatsapp_setting,
} from "helper/permissionsCheck";

// ==============================|| WHATSAPP SETTINGS - MANAGEMENT ||============================== //

const AllWhatsAppSetting = ({ allWhatsappsetting }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchCreateWhatsAppSetting({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    // console.log("whatsapp", allWhatsappsetting);
  }, [allWhatsappsetting]);

  const columns = AllWhatsappSettingCOlumns();

  columns.push(can_edit_whatsapp_setting ? {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          <IconButton
            className="mx-2"
            type="primary"
            onClick={() => {
              navigate("/settings/add-whatsapp-setting", { state: record });
            }}
          >
            <EditOutlined />
          </IconButton>
        </span>
      );
    },
  } : <></>);

  return (
    <>
<div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "67px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "0 16px",
    backgroundColor: "#fff",
    flexWrap: "wrap", // Ensures better handling of elements on mobile
  }}
>
  <Grid container alignItems="center" justifyContent="flex-start" xs={12} sm={8}>
    <Grid item>
      <span
        style={{
          fontSize: "22px",
          fontWeight: "600",
          whiteSpace: "nowrap", // Prevents text from breaking
          overflow: "hidden", // Hides overflow text if needed
          textOverflow: "ellipsis", // Adds ellipsis for overflowed text
        }}
      >
        All WhatsApp Settings
      </span>
    </Grid>
  </Grid>

  {can_add_whatsapp_setting && (
    <Grid container alignItems="center" justifyContent="flex-end" xs={12} sm={4}>
      <Link to={"/settings/add-whatsapp-setting"}>
        <Button
          disableElevation
          className="px-2"
          size="large"
          type="submit"
          style={{
            padding: "8px 16px", // Adjusting padding for better mobile fit
            minWidth: "auto", // Allows button to adapt its width
          }}
        >
          <span className="mx-2">
            <IoMdAddCircle color="#00529D" size={26} />
          </span>
          Add WhatsApp Setting
        </Button>
      </Link>
    </Grid>
  )}
</div>


      <MainCard>
      <div style={{ overflowX: "auto" }}>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={allWhatsappsetting?.data ?? []}
            columns={columns}
          />
        </Spin>
      </div>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allWhatsappsetting: state.AllWhatsappSettingReducer.allWhatsappsetting,
  };
};

export default connect(mapStateToProps)(AllWhatsAppSetting);
