import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Descriptions, Empty, Pagination } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { dispatch } from "store/index";
import { callsLogColumns } from "components/columns/columns";
import { FetchAllCallLogs, FetchGetAllLogs } from "store/action/index";
import AudioPlayer from '../../components/inputs/AudioPlayer'
import * as url from "../../store/constant/Endpoints";


const CallLogs = ({ alllogsdataaa }) => {
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState({ page: 1, activity_id: 2 });
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        setLoading(true)
        dispatch(FetchGetAllLogs({ params: params, setLoading: setLoading }));
    }, [params]);

    const columns = callsLogColumns({ params, setparams });

    const handlePageChange = (page) => {
        setparams({ ...params, page });
    };

    useEffect(() => {
        if (!alllogsdataaa?.data || !Array.isArray(alllogsdataaa.data)) {
            setDataSource([]);
            return;
        }

        const ReadyToFormatView = alllogsdataaa.data.map((log) => ({
            ...log,
            questions: log.questions?.map((question) => ({
                question: question?.question,
                answers: question?.call_answer?.length === 1
                    ? question?.call_answer[0]?.answer
                    : question?.call_answer?.map((answer) => answer?.answer),
            })),
        }));

        setDataSource(ReadyToFormatView);
        console.log("AllDatatataat", alllogsdataaa)
    }, [alllogsdataaa]);

    const expandedRowRender = (record) => {
        return (
            <div>
                <Descriptions title="Call Answers" bordered column={1}>
                    {Array.isArray(record.call_answer) && record.call_answer.length ? record.call_answer.map((item) => (
                        <Descriptions.Item key={item.id} label={item.question.question}>
                            {(() => {
                                try {
                                    if (item.answer.includes('undefined')) {
                                        return 'No Answer'
                                    }
                                    const parsedAnswer = JSON.parse(item.answer);
                                    return Array.isArray(parsedAnswer)
                                        ? parsedAnswer.join(', ')
                                        : parsedAnswer;
                                } catch {
                                    return item.answer;
                                }
                            })()}
                        </Descriptions.Item>
                    )) : <Empty />}
                </Descriptions>
                <Descriptions title="Call Logs" bordered column={1}>
                    {Array.isArray(record.call_log) && record.call_log.length ? record.call_log.map((log) => (
                        <Descriptions.Item key={log.id} >
                            <div>
                                <div>
                                    <strong>Status:</strong> {log.call_status}
                                </div>
                                {log.call_status !== 'Terminated' && <div>
                                    < strong > Started On:</strong> {log.call_started_on}
                                </div>}
                                <div>
                                    <strong>Ended On:</strong> {log.call_ended_on}
                                </div>
                                <div>
                                    {log?.download_path && (
                                        <div>
                                            <strong>Call Recording:</strong>
                                            <AudioPlayer audioUrl={url.audio_url + log?.download_path} />
                                        </div>
                                    )}


                                </div>
                            </div>
                        </Descriptions.Item>
                    )) : <Empty />}
                </Descriptions>
            </div>
        );
    };
    return (
        <>
            <MainCard>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender
                        }}
                        dataSource={dataSource ?? []}

                        pagination={false}
                        loading={loading}
                        rowKey="id"
                    />
                </div>
                <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={alllogsdataaa?.current_page || 1}
                        total={alllogsdataaa?.total || 0}
                        pageSize={alllogsdataaa?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div>
            </MainCard>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alllogsdataaa: state?.GetAllLogsReducer?.alllogsdataaa,
    };
};

export default connect(mapStateToProps)(CallLogs);