import { ALL_SMS_TEMPLATES } from "../../constant/ActionType";

export default function AllSmsTemplatesReducer(
    state = {
        allsmsTemplates: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_SMS_TEMPLATES:
            return {
                ...state,
                allsmsTemplates: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}