import { GETALL_PERMISSION } from "../constant/ActionType";

export default function GetAllPermissionReducer(
  state = {
    getallpermissions: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case GETALL_PERMISSION:
      return {
        ...state,
        getallpermissions: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
