import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import { CreateSmSSettings, UpdateSmSSettings } from "store/action/index";
import HandlePasswordDecrypte from "helper/password-decrypte";


const { Option } = Select;

const SmsSetting = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [ConditionDisabledCHeck, setDisabledCOndotionCHeck] = useState(false)
  const [loadinBtn, setLoadingBtn] = useState(false)

  const isEditing = location.state ? true : false;
  const initialData = location.state ? location.state : null;

  useEffect(() => {

    if (isEditing && initialData && form.getFieldValue('is_default') == true) {
      setDisabledCOndotionCHeck(true)
    } else {
      setDisabledCOndotionCHeck(false)
    }
  }, [isEditing, initialData, form])

  useEffect(() => {
    if (isEditing && initialData) {
      form.setFieldsValue({
        access_token: initialData.access_token,
        api_id: initialData.api_id,
        api_url: initialData.api_url,
        name: initialData.name,
        source: initialData.source,
        username: initialData.username,
        password: initialData.password,
        active: initialData.active == 1 ? true : false,
        is_default: initialData.is_default == 1 ? true : false,
      });
    }
    console.log("sdjdskdjskjd", initialData)
  }, [form, initialData, isEditing]);

  const onFinish = (values) => {
    setLoadingBtn(true)
    if (isEditing) {
      message.success("Call setting updated successfully!");
      const payload = {
        ...(values && { id: initialData?.id, _method: "PUT" }),
        access_token: values.access_token,
        api_id: values.api_id,
        api_url: values.api_url,
        password: values.password,
        name: values.name,
        source: initialData.source,
        username: values.username,
        active: values.active,
        is_default: values.is_default,
      };
      dispatch(UpdateSmSSettings({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
    } else {
      message.success("Call setting created successfully!");
      dispatch(CreateSmSSettings({ fieldData: values, setLoadingBtn: setLoadingBtn }));
    }
  };

  return (
    <MainCard>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{
        is_default: false,
        active: true,
      }}>
        <Form.Item
          name="access_token"
          label="Access Token"
          tooltip="Enter your Access Token"
          rules={[{ required: true, message: "Please enter the SMS provider" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="api_id"
          label="Api id"
          tooltip="Enter the SMS provider API key"
          rules={[{ required: true, message: "Please enter the API Key" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="api_url"
          label="Api Url"
          tooltip="Enter Api Url"
          rules={[{ required: true, message: "Please enter the Api Url" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          tooltip="Enter the Password"
          rules={[{ required: true, message: "Enter the Password" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          tooltip="Enter your name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          tooltip="Enter your Username"
          rules={[{ required: true, message: "Please enter Username" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="source"
          label="Source"
          tooltip="Enter your source"
          rules={[{ message: "Please enter source" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="active"
          label="Status"
          tooltip="Select the active status"
          rules={[{ required: true, message: "Please select the status" }]}
        >
          <Select>
            <Option value={true}>Active</Option>
            <Option value={false}>Deactive</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="is_default"
          label="Default"
          tooltip="Select the Default"
          rules={[{ required: true, message: "Please select default" }]}
        >
          <Select disabled={ConditionDisabledCHeck}
          >
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadinBtn}>
            {isEditing ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </MainCard>
  );
};

export default SmsSetting;
