import React from 'react';
import CryptoJS from 'crypto-js';

const HandlePasswordDecrypte = (paramPasswordString) => {
    // Replace with your Laravel APP_KEY
    const appKey = "base64:moUzzZhnyItK+6SnPoCicvDd5J0u8DkXLI0083hBCy4=";
    const base64Key = appKey.split("base64:")[1];
    const key = CryptoJS.enc.Base64.parse(base64Key);
    try {
        const decodedText = atob(paramPasswordString);
        const encryptedJson = JSON.parse(decodedText);
        const iv = CryptoJS.enc.Base64.parse(encryptedJson.iv);
        const ciphertext = CryptoJS.enc.Base64.parse(encryptedJson.value);
        // Decrypt the ciphertext using the key and IV
        const decrypted = CryptoJS.AES.decrypt(
            {
                ciphertext: ciphertext,
            },
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        // Convert the decrypted bytes to UTF-8 string
        const originalText = decrypted.toString(CryptoJS.enc.Utf8);
        // Debug: log the original decrypted text
        return originalText;
    } catch (error) {
        console.error("Error during decryption:", error);
        return null;
    }
};

export default HandlePasswordDecrypte