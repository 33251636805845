import { VIEW_ROLES } from "../constant/ActionType";

export default function ViewAllRolesReduncerre(
  state = {
    viewGetrole: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case VIEW_ROLES:
      return {
        ...state,
        viewGetrole: action.data,
        statuscode: action.status,
      };

    default:
  }

  return state;
}
